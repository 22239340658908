import { Inject, Injectable } from '@angular/core';

import { find, get } from 'lodash-es';
import { AxisName, ChartAPI, ChartConfiguration, generate, LabelOptionsWithThreshold, TooltipOptions } from 'c3';

import { CHART_TOP_MARGIN, COMBO_PIN_MARGIN } from '../chart.constants';
import { pixels } from '@core/utils';
import {
  AxisAdditionalOptions,
  C3Data,
  C3DataPoint,
  // C3DataSeries,
  C3PieDonutBarOptions,
  ChartAreaBetweenMetricsConfig,
  ChartAreaBetweenMetricsItem,
  ChartData,
  ChartLegendItem,
  ChartOptions,
  ChartType,
  FormatLabelObject,
  GeneratedLegendData,
  MetricStepType,
  PointData,
} from '@core/model';
import { ChartTypes, LegendType } from '@core/enums';
import { DOCUMENT_TOKEN } from '@core/constant';
import { ChartService } from '@shared/components/chart/chart.service';

// TODO: it would be good to use a builder pattern
@Injectable()
export class C3AdapterService {
  private chartC3: ChartAPI;

  constructor(@Inject(DOCUMENT_TOKEN) private document: Document, private chartService: ChartService) {}

  getChart(config: ChartConfiguration): ChartAPI {
    this.chartC3 = generate(config);

    return this.chartC3;
  }

  replaceNullValues(data: [string, ...(string | number)[]][]): [string, ...(string | number)[]][] {
    return data.map(series => [series[0], ...series.slice(1).filter(value => value !== null)]);
  }

  calculateBarWidth(chartWidth, dataPoints): number {
    const spacePerDataPoint = chartWidth / dataPoints;

    return spacePerDataPoint * 0.8;
  }

  getOptions(options: ChartOptions, data: ChartData[]): ChartConfiguration {
    const isBarChart = this.isClusteredBarChart(options);
    const chartWidth =
      this.isComboChart(options.chart?.type) || isBarChart ? options.chart?.width : options.chart?.height;

    let chartOptions: ChartConfiguration = {
      data: {},
      axis: {
        x: {
          label: {
            text: !isBarChart ? options?.chart?.xAxis?.axisLabel?.toUpperCase() : '',
            position: 'outer-center',
          },
          type: isBarChart ? 'category' : 'indexed',
          tick: {
            width: isBarChart ? this.calculateBarWidth(chartWidth, data.length) : undefined,
          },
        },
        y: {
          padding: {
            top: options?.chart?.height * CHART_TOP_MARGIN,
            bottom: 0,
          },
          min: options.chart.forceY[0],
          label: {
            text: options?.chart?.yAxis?.axisLabel,
            position: 'outer-middle',
          },
          show: !(isBarChart && (options.chart.hideYAxisOnBarChart || options.chart.hideXAxisOnBarChart)),
          tick: {
            format: this.tickFormatYAxis.bind(this, data[0]?.isPercentYAxis, data[0]?.isWholeNumberYAxis),
          },
        },
        rotated: options.chart.rotatedAxes,
      },
      grid: {
        y: {
          show: !(isBarChart && (options.chart.hideYAxisOnBarChart || options.chart.hideXAxisOnBarChart)),
        },
      },
      size: {
        width: chartWidth - options?.chart?.margin?.right - (options.isPDF ? COMBO_PIN_MARGIN : 0),
        height: options?.chart?.height,
      },
    };

    chartOptions = this.applyMaxOfAxis(options, chartOptions, data);

    return options.chart.axes === 'y2' ? this.extendOptions(options, data, chartOptions) : chartOptions;
  }

  getC3StepType(data: ChartData[]): MetricStepType {
    return (find(data, item => item.stepLineType) as ChartData)?.stepLineType;
  }

  getC3Colors(data: ChartData[], options: ChartOptions): Record<string, string> {
    const color = {};
    const isPieOrDonutOrBar = this.isPieOrDonutChart(options) || this.isClusteredBarChart(options);
    const processedMetricTitles: Set<string> = new Set();

    data.forEach((item: ChartData, i: number) => {
      if (item.color && (!isPieOrDonutOrBar || !processedMetricTitles.has(item.metricTitle))) {
        const key = isPieOrDonutOrBar ? item.metricTitle : `data${i}`;

        if (isPieOrDonutOrBar) {
          processedMetricTitles.add(item.metricTitle);
        }

        item.hideMetricOnChart ? (color[key] = 'transparent') : (color[key] = item.color);
      }
    });

    return color;
  }

  getChartColor(chartType: ChartTypes, colorArray: string[]): (color: string, data: any) => string {
    return (color: string, data: any) => {
      if (chartType === ChartTypes.clusteredBarChart) {
        return colorArray[data.index];
      }

      return color;
    };
  }

  getC3X(data: ChartData[]): ['x', ...number[]] {
    return [
      'x',
      ...data
        .reduce((acc: number[], item: ChartData) => [...acc, ...item.values.map(value => value.x)], [])
        .filter((item, i, arr) => arr.indexOf(item) === i)
        .sort((x1, x2) => x1 - x2),
    ];
  }

  getC3Data(dataChart: ChartData[], x: ['x', ...number[]], chartType: ChartType): C3Data {
    const groups = {};
    const types = {};
    let data: [string, ...number[]][];

    if (chartType === ChartTypes.clusteredBarChart) {
      const combinedData = dataChart.flatMap((item, i) => {
        types[`data${i}`] = ChartTypes.bar;

        return this.getItemValues(item.values, x);
      });

      // Group all values into a single data series so they can be displayed with labels from 'categories' with C3.js library
      data = [['data0', ...combinedData]];
    } else {
      data = dataChart.map((item, i) => {
        const isPieOrDonut = item.chartType === ChartTypes.pie || item.chartType === ChartTypes.donut;
        const value = this.getDataForPieOrDonutChart(item.values);
        const values = isPieOrDonut ? [value] : this.getItemValues(item.values, x);
        const id = isPieOrDonut ? item.metricTitle : `data${i}`;

        if (this.isComboChart(chartType) && item.stackedMetric) {
          groups[item.stackedGroup] = [...(groups[item.stackedGroup] || []), id];
        }

        types[id] = [ChartTypes.areaAbove, ChartTypes.areaBelow].includes(item.chartType)
          ? ChartTypes.line
          : item.chartType;

        return [id, ...values];
      });
    }

    return { data, types, groups: Object.values(groups) };
  }

  tickFormatYAxis(isPercentYAxis: boolean, isWholeNumberYAxis: boolean, d: number): string {
    if (isPercentYAxis) {
      return `${d3.format(',.2f')(d)}%`;
    }

    if (isWholeNumberYAxis) {
      return d3.format(',')(d);
    }

    return `$${d3.format(',')(d)}`;
  }

  getChartAreaBetweenMetricsConfig(data: ChartData[], x: []): ChartAreaBetweenMetricsConfig {
    let config: ChartAreaBetweenMetricsConfig;
    const json: ChartAreaBetweenMetricsItem<number>[] = [];
    const areaBelow = data.find(item => item.chartType === ChartTypes.areaBelow);
    const areaAbove = data.find(item => item.chartType === ChartTypes.areaAbove);
    const areaBelowY = areaBelow?.values?.map(value => value.y);
    const areaAboveY = areaAbove?.values?.map(value => value.y);

    if (areaBelowY?.length && areaAboveY?.length) {
      x.forEach((xVal, i) => {
        json.push({
          topline: areaBelowY[i] === undefined ? null : areaBelowY[i],
          bottomline: areaAboveY[i] === undefined ? null : areaAboveY[i],
          x: xVal,
        });
      });

      const colors = {
        bottomline: areaAbove.color,
        topline: areaBelow.color,
      };

      config = {
        json,
        colors,
      };
    }

    return config;
  }

  addSpaceBetweenTspansLabel(id: string): void {
    const texts = d3.selectAll(`#c3Chart-${id} .c3-axis-x text`);

    texts[0].forEach(element => {
      const tspans = (element as HTMLElement).querySelectorAll('tspan');
      tspans.forEach((tspan, index) => {
        if (index) {
          tspan.setAttribute('dy', '1em');
        }
      });
    });
  }

  fillAreaBetweenMetrics(chartC3, config: ChartAreaBetweenMetricsConfig, chartId: string): void {
    const items = config.json;
    const indexies = d3.range(items.length);
    const yscale = chartC3.internal.y;
    const xscale = chartC3.internal.x;
    const area = d3.svg
      .area()
      .x((d: any) => {
        return xscale(items[d].x);
      })
      .y0((d: any) => {
        return yscale(items[d].bottomline);
      })
      .y1((d: any) => {
        return yscale(items[d].topline);
      })
      .interpolate('linear');
    const exist = get(d3.select(`#${chartId} svg .area-between`), '[0][0]');

    if (!exist) {
      d3.select(`#${chartId} svg g`)
        .append('path')
        .datum(indexies)
        .attr('class', 'area-between')
        .style('fill', config.colors.topline)
        .attr('d', area as any);
    }
  }

  applyChartFontSizeAxesLabels(options: ChartOptions): void {
    const id = `#c3Chart-${options.id}`;
    const isPieOrDonut = this.isPieOrDonutChart(options);

    const applyStyleToAll = (selector: string, fontSize: string): void => {
      // TODO: need to check why setting style by 3d doesn't work
      this.document.querySelectorAll(selector).forEach((item: Element) => {
        item['style'].fontSize = fontSize;
      });
    };
    const applyStyleToOne = (selector: string): void => {
      d3.select(selector).style('font-size', options.chart.chartFontSizeAxesNames);
    };

    applyStyleToOne(`${id} .c3-axis-x-label`);
    applyStyleToOne(`${id} .c3-axis-y-label`);
    applyStyleToOne(`${id} .c3-axis-y2-label`);

    if (isPieOrDonut) {
      applyStyleToAll(`${id} .c3-legend-item`, options.chart.fontSizeOfChartLabels);
      applyStyleToAll(`${id} .c3-chart-arc text`, options.chart.fontSizeOfChartLabels);
    }

    if (this.isClusteredBarChart(options)) {
      applyStyleToAll(`${id} .c3-axis-x g.tick text tspan`, options.chart.chartFontSizeXAxesLabels);
      applyStyleToAll(`${id} .c3-axis-y g.tick text tspan`, options.chart.chartFontSizeYAxesLabels);
      applyStyleToAll(`${id} .c3-chart-texts .c3-text`, options.chart.fontSizeValueOnChart);
    }
  }

  setStrokeDasharray(chartOptions: ChartOptions, chartData: ChartData[], selectors: string[]): void {
    chartData.forEach((item, i) => {
      d3.select(`#c3Chart-${chartOptions.id} .c3-line-${selectors[i]}`).style(
        'stroke-dasharray',
        `${item.dashLength} ${item.dashInterval}`
      );
    });
  }

  getDonutOptions(options: ChartOptions): C3PieDonutBarOptions {
    const roundWidth = (options.chart.donutWidth * pixels(options.chart.height.toString())) / 200;

    const donutOptions = {
      donut: {
        width: roundWidth,
        ...this.getLabels(options),
      },
    };

    return options.chart.type === ChartTypes.donut ? donutOptions : undefined;
  }

  getPieOptions(options: ChartOptions): C3PieDonutBarOptions {
    const pieOptions = {
      pie: {
        ...this.getLabels(options),
      },
    };

    return options.chart.type === ChartTypes.pie ? pieOptions : undefined;
  }

  getBarChartOptions(options: ChartOptions, data: ChartData[]): C3PieDonutBarOptions {
    const isBarCharts = data.some(
      metric => metric.chartType === ChartTypes.bar || metric.chartType === ChartTypes.clusteredBarChart
    );

    const barOptions = {
      bar: {
        width: {
          ratio: Number(options.chart.barWidth) / 100,
        },
      },
    };

    return isBarCharts && options.chart.barWidth ? barOptions : undefined;
  }

  getTooltips(options: ChartOptions, data: ChartData[]): Record<string, TooltipOptions> {
    const isPieOrDonut = this.isPieOrDonutChart(options);
    const sum = this.getSum(data);
    const getPercent = (value: number): string => this.getValueInPercent(value, sum);
    const format = {
      // @ts-ignore
      name: function (name: string, ratio: number, id: string) {
        if (options.chart.legendType === LegendType.values) {
          name = `${id}, ${name}`;
        }

        const index = name.indexOf(',');

        return index !== -1 ? name.replace(name.slice(index), '') : name;
      },
    };
    // @ts-ignore
    const contentsFormatter = function (d: C3DataPoint[], defaultTitleFormat, defaultValueFormat, color) {
      // @ts-ignore
      const name = this.config.tooltip_format_name(d[0].name, null, d[0].id);
      // @ts-ignore
      const selectors = this.CLASS;

      return `
           <table class="${selectors.tooltip}">
            <tbody>
            <tr class="${selectors.tooltipName}-${name}">
              <td style="font-size: ${options.chart.fontSizeOfChartLabels}">
                <span style="background-color: ${color(d[0].id)}"></span>
                ${name}
              </td>
              <td class="value" style="font-size: ${options.chart.fontSizeOfChartLabels}">${getPercent(d[0].value)}</td>
            </tr>
            </tbody>
          </table>
          `;
    };

    return {
      tooltip: {
        show: isPieOrDonut,
        format: isPieOrDonut ? format : {},
        // @ts-ignore
        contents: isPieOrDonut ? contentsFormatter : undefined,
      },
    };
  }

  isPieOrDonutChart(options: ChartOptions): boolean {
    return options.chart.type === ChartTypes.pie || options.chart.type === ChartTypes.donut;
  }

  isClusteredBarChart(options: ChartOptions): boolean {
    return options.chart.type === ChartTypes.clusteredBarChart;
  }

  isComboChart(type: string): boolean {
    return type === ChartTypes.comboChart;
  }

  isTooltipValueHide(options: ChartOptions): boolean {
    return options.chart.valueDisplay !== 'tooltip' && options.chart.valueDisplay !== 'chart-tooltip';
  }

  getFilterData(data: ChartData[]): ChartData[] {
    return data.filter((item: ChartData) => this.getDataForPieOrDonutChart(item.values));
  }

  generateLegend(chartC3: ChartAPI, data: ChartData[], options: ChartOptions): GeneratedLegendData {
    const position = options?.chart.legendPosition;
    let legendData = [];
    const legendInfo = this.getLegendInfo(data, options);

    if (chartC3 && options.chart.legendType !== LegendType.hide) {
      legendData = chartC3.data().map(item => {
        return {
          id: item.id,
          title: legendInfo[item.id]?.title,
          description: legendInfo[item.id]?.description,
          color: chartC3.color(item.id),
          dimmed: false,
        };
      });
    }

    return { legendData, position };
  }

  hexToRgba(hexColor: string): string {
    if (hexColor.length === 4) {
      hexColor = '#' + hexColor[1] + hexColor[1] + hexColor[2] + hexColor[2] + hexColor[3] + hexColor[3];
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);

    if (!result) {
      return null;
    }

    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return `rgba(${r},${g},${b})`;
  }

  getLegendInfo(data: ChartData[], options: ChartOptions): Record<string, ChartLegendItem> {
    const { type, legendType, secondLineDescription } = options.chart;

    if ((type !== ChartTypes.donut && type !== ChartTypes.pie) || legendType === LegendType.hide) {
      return {};
    }

    const sum = this.getSum(data);

    return data.reduce((acc: Record<string, ChartLegendItem>, item: ChartData) => {
      const value = this.getDataForPieOrDonutChart(item.values);
      const nameAsValue = this.getValueInPercent(value, sum);

      let displayTitle;
      let displayDescription;

      switch (legendType) {
        case LegendType.labels:
          displayTitle = item.metricTitle;
          break;
        case LegendType.values:
          displayTitle = nameAsValue;
          break;
        case LegendType.labelsValues:
          displayTitle = `${item.metricTitle}, ${nameAsValue}`;
          break;
        case LegendType.labelsValuesInTwoLines:
          displayTitle = item.metricTitle;
          displayDescription = { secondLineDescription, nameAsValue };
          break;
        default:
          return acc;
      }

      acc[item.metricTitle] = {
        title: displayTitle,
        description: displayDescription,
      };

      return acc;
    }, {});
  }

  getBarLabels(options: ChartOptions, data: ChartData[]): boolean | { format: FormatLabelObject } {
    if (this.isClusteredBarChart(options) && options.chart.valueDisplayOnBarChart === 'displayOnTheChart') {
      return {
        format: data.reduce(
          (acc, metric) => ({
            ...acc,
            [`data${metric.order}`]: value =>
              this.tickFormatYAxis(metric.isPercentYAxis, metric.isWholeNumberYAxis, value),
          }),
          {}
        ),
      };
    } else {
      return false;
    }
  }

  getAxes(dataChart: ChartData[], options: ChartOptions): Record<string, AxisName> {
    const axes = dataChart.reduce((acc: Record<string, AxisName>, item: ChartData, i: number) => {
      return {
        [`data${i}`]: item.yAxis,
        ...acc,
      };
    }, {});

    return this.isComboChart(options.chart.type) && options.chart.axes === 'y2' ? axes : undefined;
  }

  private getLabels(options: ChartOptions): Record<string, LabelOptionsWithThreshold> {
    const config = {
      label: {
        show: options.chart.valueDisplay !== LegendType.hide && options.chart.valueDisplay !== 'tooltip',
      },
    };

    return this.isPieOrDonutChart(options) ? config : undefined;
  }

  private getItemValues(values: PointData[], x: ['x', ...number[]]): number[] {
    return x.slice(1).map(xItem => {
      const point = values.find(item => item.x === xItem);

      return point ? point.y : null;
    });
  }

  private getDataForPieOrDonutChart(data: PointData[]): number {
    return data.reduce((acc: number, item: PointData) => acc + item.y, 0);
  }

  private getSum(data: ChartData[]): number {
    return data.reduce((acc: number, item: ChartData) => acc + this.getDataForPieOrDonutChart(item.values), 0);
  }

  private getValueInPercent(value: number, sum: number): string {
    return `${((value / sum) * 100).toFixed(1)}%`;
  }

  private getAxisAdditionalOptions(data: ChartData[], axis: 'y' | 'y2'): AxisAdditionalOptions {
    const getDataForXAxis = (data: ChartData[], axis: 'y' | 'y2'): ChartData[] => {
      return data
        .filter((item: ChartData) => item.yAxis === axis)
        .sort((a: ChartData, b: ChartData) => +a.key - +b.key);
    };
    const axisMetrics = getDataForXAxis(data, axis);
    const forceY = this.chartService.getForceY(axisMetrics);

    return {
      forceY: forceY[0],
      isPercentYAxis: axisMetrics[0]?.isPercentYAxis,
      isWholeNumberYAxis: axisMetrics[0]?.isWholeNumberYAxis,
    };
  }

  private extendOptions(
    options: ChartOptions,
    data: ChartData[],
    extendedOptions: ChartConfiguration
  ): ChartConfiguration {
    const firstYAxis = this.getAxisAdditionalOptions(data, 'y');
    const secondYAxis = this.getAxisAdditionalOptions(data, 'y2');

    return {
      ...extendedOptions,
      axis: {
        ...extendedOptions.axis,
        y: {
          ...extendedOptions.axis.y,
          min: firstYAxis.forceY,
          tick: {
            format: this.tickFormatYAxis.bind(this, firstYAxis.isPercentYAxis, firstYAxis.isWholeNumberYAxis),
          },
        },
        y2: {
          show: !options.chart.hideAdditionalYAxis,
          label: {
            text: options.chart.additionalYAxisName,
            position: 'outer-middle',
          },
          // min: secondYAxis.forceY,
          tick: {
            format: this.tickFormatYAxis.bind(this, secondYAxis.isPercentYAxis, secondYAxis.isWholeNumberYAxis),
          },
        },
      },
    };
  }

  private applyMaxOfAxis(
    options: ChartOptions,
    extendedOptions: ChartConfiguration,
    data: ChartData[]
  ): ChartConfiguration {
    const range = this.chartService.getDataRange(data);

    if (this.isClusteredBarChart(options) || this.isComboChart(options.chart.type)) {
      const { rotatedAxes, yAxisMaxValueMetric, xAxisMaxValueMetric } = options.chart;
      const value = this.isClusteredBarChart(options) && rotatedAxes ? xAxisMaxValueMetric : yAxisMaxValueMetric;

      if (value) {
        extendedOptions.axis.y.max = range.maxY && range.maxY > value ? range.maxY : (value as number);
      }
    }

    return extendedOptions;
  }
}

import { Inject, Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { select, Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { cloneDeep, groupBy, isNull, uniq } from 'lodash-es';
import { catchError, concatMap, filter, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of, throwError } from 'rxjs';

import {
  getDependentPageLoadingSuccess,
  getDependentPagesConfigLoadingFailure,
  getDependentPagesConfigLoadingPending,
  getDependentPagesConfigLoadingSuccess,
  getDependentPagesLoadingFailure,
  getEndPageLoadingSuccess,
  getEndPagesLoadingFailure,
  getEndPagesLoadingPending,
  getEndPagesLoadingSuccess,
  getSalesConceptDependentPagesLoadingSuccess,
  getSalesConceptLoadingSuccess,
  getSalesConceptsLoadingFailure,
  getSalesConceptsLoadingPending,
  getSalesConceptsLoadingSuccess,
  setNavbarCustomPages,
} from '../setup.actions';
import { AppState } from '../../../../reducers';
import { APIService, Global, TimeService, Utils } from '@shared/services';
import { getRules } from '../../modals/cover-sheet-modal/cover-sheet-modal.selectors';
import { AuthService, UserService } from '@assurance/um-services';
import {
  ADDITIONAL_DATA_INSERT_TYPES,
  CALCULATED_SETTINGS,
  COMPILE_SETTINGS,
  CUSTOM_PAGE_CONFIGS_SESSION_STORAGE_KEY,
  PrecompilePaths,
  READONLY_PRODUCT_FIELDS,
  RETIREMENT_SHORTFALL_SETTINGS_FIELDS,
} from '@shared/constants';
import { getDependentPermissions } from '@ngrx-app/global.selectors';
import { getDependentPagesConfig, getNavbarCustomPages, getNavbarDependentPages } from '../setup.selectors';
import { getGraphValue, getPresentationConfigs, getPresentationLocked } from '../../redux/configs/selectors';
import { PlansService } from '../../presentation-plans/plans.service';
import { presentationPlansUpdateSuccess } from '../../presentation.actions';
import { PRODUCTS_SESSION_STORAGE_KEY } from '../setup.constant';
import {
  ActivePlan,
  CareerPlan,
  CustomHttpParams,
  ExtendedPlaceholderMetadata,
  ImagePlaceholder,
  InsertType,
  PinValues,
  PlaceholderDropdown,
  PlaceholderProductSelector,
  PlaceholderTab,
  Presentation,
  PresentationConfig,
  ResponseType,
  TextPlaceholder,
  TimeDate,
  VariablePlaceholder,
  XAxisSourceType,
} from '@core/model';
import {
  CompiledOptions,
  CompileParams,
  CompilePageOptions,
  CustomPage,
  CustomPageIds,
  CustomPageInsert,
  CustomPageInsertMetadata,
  CustomPageItemChart,
  CustomPageResponse,
  CustomPagesConfig,
  CustomPagesItemConfig,
  CustomPagesMappedConfigs,
  CustomPageValues,
  DataSourcesConfig,
  DependentPagesParams,
  Insert,
  InsertByCustomPageUUID,
  InsertConfig,
  NavbarCustomPagesData,
  PageConfig,
  SalesConceptDataResponse,
  ShortfallFields,
} from '@shared/models';
import { DOCUMENT_TOKEN, LOCATION_TOKEN } from '@core/constant';
import { INSERT_TYPE, PresentationRoutes } from '@core/enums';
import { isDefined } from '@core/utils';
import { getPresentationId } from '../../presentation.selectors';

@Injectable({
  providedIn: 'root',
})
export class CustomPageService {
  private compilePage = new BehaviorSubject<boolean>(false);
  private scrollPosition: number;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private global: Global,
    private store: Store<AppState>,
    private apiService: APIService,
    private utils: Utils,
    private plansService: PlansService,
    private domSanitizer: DomSanitizer,
    private timeService: TimeService,
    @Inject(LOCATION_TOKEN) private location: Location,
    @Inject(DOCUMENT_TOKEN) private document: Document
  ) {}

  public getCompilePage(): Observable<boolean> {
    return this.compilePage.asObservable();
  }

  public setCompilePage(value: boolean): void {
    this.compilePage.next(value);
  }

  setScrollPosition(position: number): void {
    this.scrollPosition = position;
  }

  getScrollPosition(): number {
    return this.scrollPosition;
  }

  setScrollPositionOFElement(): void {
    if (this.global.isSharedPresentation()) {
      this.document.getElementById('wrap-scrollable-content')?.scrollTo(0, this.getScrollPosition());
    }
  }

  setScrollPagePosition(): void {
    const scrollableElement = this.document.getElementById(
      this.global.isSharedPresentation() ? 'wrap-scrollable-content' : 'scrollable-container'
    );

    this.setScrollPosition(scrollableElement.scrollTop);
  }

  public extendAnchorLinks(url: string, selectedCustomPage: CustomPageValues) {
    if (!selectedCustomPage?.htmlBody) return;

    const host = url.split('#');
    const anchorUrlRegEx = /(href="#)(.*?)(?=")/gi;
    const newTabRegEx = /(href="#.*") (target="_blank")/gi;
    let htmlBody = selectedCustomPage.htmlBody;
    let matchElem = [...htmlBody.matchAll(anchorUrlRegEx)].flatMap(anchor => anchor[2]);
    matchElem = uniq(matchElem);
    htmlBody = htmlBody.replace(newTabRegEx, '$1');
    matchElem.forEach(elem => {
      const href = `#${elem}`;
      htmlBody = htmlBody.replace(new RegExp(href, 'g'), `${host[0]}${href}`);
    });
    selectedCustomPage.htmlBody !== htmlBody && (selectedCustomPage.htmlBody = htmlBody);
  }

  public hideUnavailableSalesConcepts(salesConcepts: CustomPageValues[], dndList: PageConfig[]): void {
    if (salesConcepts && _.isArray(salesConcepts)) {
      _.remove(dndList, item => {
        return item.config.isSalesConcept && !_.find(salesConcepts, { config: { uiId: item.config.uiId } });
      });
    }
  }

  public getInsertValue(
    configs: CustomPagesConfig[],
    selectedCustomPage: CustomPageValues,
    insert: CustomPageInsert
  ): string | number {
    let defaultValue;

    switch (insert.metadata.insertType) {
      case INSERT_TYPE.text:
        defaultValue = (insert.metadata as TextPlaceholder).placeholderText;
        break;
      case INSERT_TYPE.variable:
        //could be null in case of variable is inline editable
        defaultValue = (insert.metadata as VariablePlaceholder).placeholderDefaultValue?.toString().trim() || null;
        break;
      case INSERT_TYPE.image:
        defaultValue = (insert.metadata as ImagePlaceholder).selectedImage;
        break;
      case INSERT_TYPE.dropdown:
        defaultValue =
          (insert.metadata as PlaceholderDropdown).dropdownOptions[insert.metadata?.defaultOption]?.value
            ?.toString()
            ?.trim() || (insert.metadata as PlaceholderDropdown).placeholderSelectedValue;
        break;
      case INSERT_TYPE.productSelector:
        defaultValue = (insert.metadata as PlaceholderProductSelector).placeholderSelectedProduct;
        break;
      case INSERT_TYPE.tab:
        defaultValue = (insert.metadata as PlaceholderTab).placeholderSelectedTab;
        break;
    }

    const pages =
      selectedCustomPage.isDependentPage && selectedCustomPage.parentUiId
        ? configs.find(config => config.uiId === selectedCustomPage.parentUiId)?.pages
        : configs;

    const inserts = pages?.find(config => config.uiId === selectedCustomPage.config.uiId)?.inserts;
    // TODO: need to refct
    const found = inserts?.find(savedInsert => savedInsert.uiId === insert.config.uiId);
    const insertsWithPossibleHandlebar = [
      INSERT_TYPE.text,
      INSERT_TYPE.variable,
      INSERT_TYPE.dropdown,
      INSERT_TYPE.productSelector,
    ];

    if (found) {
      const foundType = insertsWithPossibleHandlebar.some(type => type === insert.metadata.insertType);
      const isFoundValueFormula =
        typeof found.value === 'string' && found.value.startsWith('{{') && found.value.endsWith('}}');

      return foundType && isFoundValueFormula ? defaultValue : found.value;
    } else {
      return defaultValue;
    }
  }

  public getErrorMessagesForEditablePlaceholder(
    insertType: string,
    placeholder:
      | CustomPageInsertMetadata<VariablePlaceholder>
      | CustomPageInsertMetadata<TextPlaceholder>
      | ExtendedPlaceholderMetadata,
    errors: Record<string, unknown> = {}
  ): string[] {
    if (insertType === INSERT_TYPE.variable) {
      return this.getErrorMessagesForVariablePlaceholder(
        errors,
        placeholder as CustomPageInsertMetadata<VariablePlaceholder>
      );
    }

    if (insertType === INSERT_TYPE.text) {
      return this.getErrorMessagesForTextPlaceholder(errors, placeholder as CustomPageInsertMetadata<TextPlaceholder>);
    }

    return [];
  }

  hasEnoughEligibleProducts(salesStory: CustomPageValues): boolean {
    const products = this.global.getCurrentCarrierPlans;
    const eligibleDataSources = salesStory?.eligibleDataSources;
    const minProducts = salesStory.productsRange[0];

    const eligibleProducts = products.filter(product =>
      eligibleDataSources.map(source => source.toLowerCase()).includes(product.app.toLowerCase())
    );

    return eligibleProducts.length >= minProducts;
  }

  removePinValueFromCharts(config: CustomPagesConfig): CustomPagesConfig {
    if (config?.charts) {
      const modifiedCharts = config?.charts.map(({ pinValue, ...chartWithoutPinValue }) => chartWithoutPinValue);

      return { ...config, charts: modifiedCharts };
    }

    return config;
  }

  private getErrorMessagesForVariablePlaceholder(
    errors: Record<string, unknown>,
    placeholder: CustomPageInsertMetadata<VariablePlaceholder>
  ): string[] {
    const messages = [];

    if (errors.required) {
      messages.push('This field is required');
    }

    if (errors.pattern)
      messages.push(`
      Number of decimals should be
      ${placeholder.placeholderDecimals ? 'up to' : ''}
      ${placeholder.placeholderDecimals}`);

    if (errors.max)
      messages.push(`
      Value should be less than or equal to
      ${placeholder.placeholderMaxValue}`);

    if (errors.min)
      messages.push(`
      Value should be greater than or equal to
      ${placeholder.placeholderMinValue}`);

    return messages;
  }

  private getErrorMessagesForTextPlaceholder(
    errors: Record<string, unknown>,
    placeholder: CustomPageInsertMetadata<TextPlaceholder>
  ): string[] {
    const messages = [];

    if (errors.maxlength) {
      messages.push(` Please enter up to ${placeholder.placeholderMaxLength} characters`);
    }

    return messages;
  }

  updateVersionAddedDateForShareableLink(sharedToken: string, uiId: string, newAddedDate: Date): Observable<any> {
    const newVersionDate = this.timeService.getFormattedDateWithTimezone(newAddedDate);

    return this.apiService.updateSharedPresentationVersion(sharedToken, uiId, newVersionDate).pipe(
      switchMap(response => {
        if (response.data && response.data.success) {
          return this.handleSharedPresentation(null).pipe(
            map(data => this.handleDependentPagesConfigSuccess(data)),
            catchError(error => {
              this.handleDependentPagesConfigError(error);

              return throwError(() => new Error(error));
            })
          );
        } else {
          return EMPTY;
        }
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public getSelectedDependentPagesQuantity(
    dependentPages: CustomPageValues[],
    dependentPagesConfig: CustomPagesConfig[],
    pagesList: PageConfig[]
  ): number {
    let pagesQuantity = 0;

    if (!dependentPagesConfig?.length) return pagesQuantity;

    for (const config of dependentPagesConfig) {
      if (!config.pages.length) continue;

      for (const page of config.pages) {
        page.isSelected &&
          dependentPages.find(dependentPage => dependentPage.config.uiId === page.uiId) &&
          pagesList.find(pageConfig => pageConfig.config.uiId === config.uiId) &&
          ++pagesQuantity;
      }
    }

    const configPagesEmpty = dependentPagesConfig.every(config => !config?.pages?.length);

    if (configPagesEmpty && !pagesQuantity && dependentPages?.length) {
      ++pagesQuantity;
    }

    return pagesQuantity;
  }

  private sanitizeValue(value: unknown): unknown {
    if (typeof value === 'object' && value !== null) {
      for (const key in value) {
        value[key] = this.sanitizeValue(value[key]);
      }
    }

    if (typeof value === 'string') return this.domSanitizer.sanitize(SecurityContext.HTML, value);

    return value;
  }

  public updateDestinationValues(data, metadata, viewMode: boolean, activePlans?: ActivePlan[]) {
    const currentCarrierPlans = activePlans?.length
      ? _.sortBy(activePlans, 'order').map((activePlan: ActivePlan) =>
          this.global.getCurrentCarrierPlans.find((plan: CareerPlan) => plan.id === activePlan.carrierPlanId)
        )
      : _.sortBy(this.global.getCurrentCarrierPlans, 'order');

    const updateDestination = metadata.results.reduce(
      (res, result) => {
        const value = this.sanitizeValue(this.utils.getValueByPath(data, result.responsePath));
        const destination = result.destination.split('.');

        if (
          destination[0] === 'products' &&
          !READONLY_PRODUCT_FIELDS.some(readonlyField => destination.includes(readonlyField))
        ) {
          const productIndex = destination[1];
          const destinationProduct = currentCarrierPlans[productIndex];

          if (!destinationProduct) return res;

          const productModified = !!res.products.find(product => product.id === destinationProduct.id);

          return {
            ...res,
            products: productModified
              ? res.products.map(product =>
                  product.id === destinationProduct.id
                    ? this.utils.setValueByPath(product, destination.slice(2), value)
                    : product
                )
              : [
                  ...res.products,
                  this.utils.setValueByPath(_.cloneDeep(destinationProduct), destination.slice(2), value),
                ],
          };
        }

        return res;
      },
      {
        products: [],
      }
    );

    if (updateDestination.products.length) {
      return viewMode
        ? this.updateLocalProducts(updateDestination.products)
        : this.updateProducts(updateDestination.products);
    }

    return of(null);
  }

  updatePageVersionAddedDate(presentationId: number, uiId: string, newAddedDate: Date): Observable<CustomPageResponse> {
    const newVersionDate = this.timeService.getFormattedDateWithTimezone(newAddedDate);

    return this.apiService.updateVersionAddedDate(presentationId, uiId, newVersionDate).pipe(
      switchMap(response => {
        if (response.data && response.data.success) {
          return this.apiService.getDependentPageConfig(presentationId).pipe(
            tap(configResponse => this.handleDependentPagesConfigSuccess(configResponse)),
            catchError(error => {
              this.handleDependentPagesConfigError(error);

              return throwError(() => new Error(error));
            })
          );
        }
      }),
      catchError(error => throwError(() => error))
    );
  }

  private updateLocalProducts(products = []) {
    const allProducts = this.global.getCurrentCarrierPlans.map(
      carrierPlan => products.find(product => product.id === carrierPlan.id) ?? carrierPlan
    );
    sessionStorage.setItem(PRODUCTS_SESSION_STORAGE_KEY, JSON.stringify(allProducts));

    return of(allProducts).pipe(
      tap(data => {
        this.global.setCurrentCarrierPlans = data;
        this.store.dispatch(
          presentationPlansUpdateSuccess({
            plans: data,
          })
        );
      })
    );
  }

  private updateProducts(products = []) {
    return of(...products).pipe(
      concatMap(product => {
        const productWithUpdatedId = this.global.getCurrentCarrierPlans.find(
          (plan: CareerPlan) => plan.id === product.id
        );

        return this.plansService
          .updatePlan({
            planId: productWithUpdatedId.id,
            jsonCarrierPlanData: {
              ...product,
              presentationId: this.global.getActivePresentationId,
              id: productWithUpdatedId.id,
            },
          })
          .pipe(
            switchMap(() => this.apiService.getCareerPlans(this.global.getActivePresentationId)),
            tap(response => {
              this.global.setCurrentCarrierPlans = response.data;
              this.store.dispatch(
                presentationPlansUpdateSuccess({
                  plans: response.data,
                })
              );
            })
          );
      })
    );
  }

  public callApi(data, shared: boolean): Observable<any> {
    try {
      data = {
        ...data,
        apiCallBody: data.apiCallBody ? JSON.parse(data.apiCallBody) : null,
      };
    } catch {
      data = { ...data, apiCallBody: null };
    }

    return shared ? this.apiService.callSharedCustomApi(data) : this.apiService.callCustomApi(data);
  }

  public hideSalesConcepts(dependentPermissions: Record<string, string[] | null>, dndList: PageConfig[]) {
    if (dndList && dependentPermissions) {
      const permission = dependentPermissions?.salesConcepts;
      _.remove(dndList, item => {
        if (!item.config.uiId) {
          return;
        }

        const { uiId } = this.getSalesConceptIds(item.config.uiId);

        return item.config.isSalesConcept && !permission?.includes(uiId);
      });
    }
  }

  public getCarrierEndPages(params: any): Observable<any> {
    if (!this.global.getCurrentCarrierPlans.length) {
      return of(true);
    }

    const carrierUiIds = this.global.getCurrentCarrierPlans
      .map(el => el.configjson.metadata.carrier_code)
      .filter(val => val);
    const pagesByUiIds = carrierUiIds.length ? this.getPages({ carrierUiIds, labels: 'requiredpage' }) : of(null);
    const pagesByParams = params ? this.getPages(params) : of(null);

    this.store.dispatch(getEndPagesLoadingPending());

    return forkJoin([pagesByUiIds, pagesByParams]).pipe(
      map(([carriersEndPages, paramsEndPages]) => {
        let endPageData = [];

        if (carriersEndPages && paramsEndPages) {
          endPageData = _.uniqBy([...carriersEndPages.response, ...paramsEndPages.response], 'uiId');
        } else if (paramsEndPages) {
          endPageData = paramsEndPages.response;
        } else if (carriersEndPages) {
          endPageData = carriersEndPages.response;
        }

        this.endPagesLoadingSuccess(endPageData);

        return carriersEndPages?.response;
      }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      catchError(this.handleEndPagesError.bind(this))
    );
  }

  private endPagesLoadingSuccess(endPageData: CustomPageValues[] = []): void {
    this.store.dispatch(
      getEndPagesLoadingSuccess({
        payload: this.convertEndPagesArray(endPageData),
      })
    );
  }

  public handleEndPageLoadingSuccess(endPageData: CustomPageValues[] = []): void {
    this.store.dispatch(
      getEndPageLoadingSuccess({
        payload: this.convertEndPagesArray(endPageData),
      })
    );
  }

  public hasSalesConceptTail(page: CustomPageValues): boolean;
  public hasSalesConceptTail(uiId: string): boolean;
  public hasSalesConceptTail(data: CustomPageValues | string): boolean {
    const result = this.getSalesConceptIds((data as CustomPageValues)?.config?.uiId || (data as string));

    return !!result.internalId;
  }

  public convertSalesConceptsArray(pages: any) {
    const convertedPages = [];

    if (!Array.isArray(pages)) {
      pages = [pages];
    }

    for (const page of pages) {
      page &&
        convertedPages.push(
          ...page.response.map(item => {
            const { productsMin, productsMax } = item.customFields;

            return {
              htmlBody: item.customFields.htmlBody,
              cssBody: item.customFields.cssBody,
              layout: item.customFields.layout,
              label: item.label,
              carrierUiIds: item.customFields.carrierUiIds,
              salesConceptLabel: item.salesConceptLabel,
              description: item.description && item.description.trim(),
              previewFilePath: item.customFields.previewFilePath,
              productsRange: [productsMin, productsMax],
              isEligibleForCarriers: item.customFields.isEligibleForCarriers,
              isBulkEditOfVariables: item.customFields.isBulkEditOfVariables,
              hasNewProductsOrder: item?.hasNewProductsOrder || false,
              newProductsOrder: item?.newProductsOrder,
              eligibleProductTypes: item.customFields.eligibleProductTypes,
              config: {
                uiId: this.concatSalesConceptIds(item.uiId, page.internalId),
              },
              eligibleProductsActive: item.customFields.eligibleProductsActive || false,
              isEligibleForDataSources: item.customFields.isEligibleForDataSources || false,
              eligibleDataSources: item.customFields.eligibleDataSources || [],
              unsupportedDataSourcesErrorMessage: item.customFields.unsupportedDataSourcesErrorMessage,
              insufficientProductsNumberErrorMessage: item.customFields.insufficientProductsNumberErrorMessage,
              dependentPagesLocked: item.customFields.dependentPagesLocked,
              isPageLockedAndEnabled: item.customFields.isPageLockedAndEnabled,
              dependentPages: item.customFields.dependentPages,
              portal: item.portal,
              insertsCompiled: true,
              insertIds: item.customFields.insertIds,
              inserts: this.convertInserts(item.inserts),
              versionName: item.versionName,
              _id: item._id,
            };
          })
        );
    }

    return convertedPages;
  }

  private getDataForModalFromResponse(salesConceptData: SalesConceptDataResponse[]): CustomPageValues[] {
    const salesConceptPages: CustomPageValues[] = [];

    salesConceptData.forEach(data => {
      if (data) {
        salesConceptPages.push({
          label: data.label,
          carrierUiIds: data.customFields.carrierUiIds,
          salesConceptLabel: data.salesConceptLabel,
          description: data.description?.trim(),
          previewFilePath: data.customFields.previewFilePath,
          productsRange: [data.customFields.productsMin, data.customFields.productsMax],
          isEligibleForCarriers: data.customFields.isEligibleForCarriers,
          isEligibleForDataSources: data.customFields.isEligibleForDataSources,
          eligibleDataSources: data.customFields.eligibleDataSources,
          unsupportedDataSourcesErrorMessage: data.customFields.unsupportedDataSourcesErrorMessage,
          insufficientProductsNumberErrorMessage: data.customFields.insufficientProductsNumberErrorMessage,
          eligibleProductTypes: data.customFields.eligibleProductTypes,
          eligibleProductsActive: data.customFields.eligibleProductsActive,
          config: {
            uiId: data.uiId,
          },
          insertIds: data.customFields.insertIds,
          inserts: this.convertInserts(data.inserts),
        });
      }
    });

    return salesConceptPages;
  }

  public getSalesConceptsForModal(): Observable<CustomPageValues[]> {
    this.store.dispatch(getSalesConceptsLoadingPending());

    return this.apiService.getSalesConceptData(this.global.getPresentation.id).pipe(
      map(response => {
        if (response) {
          const payload = this.getDataForModalFromResponse(response);
          this.store.dispatch(
            getSalesConceptLoadingSuccess({
              payload,
            })
          );

          return payload;
        }

        return [];
      }),
      catchError(() => {
        this.store.dispatch(getSalesConceptsLoadingFailure());

        return of(null);
      })
    );
  }

  public getSalesConcepts(
    params: CompileParams[],
    fetchDependentPages = true,
    isSalesConceptsLoading = true
  ): Observable<any> {
    if (isSalesConceptsLoading) {
      this.store.dispatch(getSalesConceptsLoadingPending());
    }

    // const uiIds = params.uiId || [];
    // const pageIds = params.pageId || [];
    let pages: any;
    let navbarPages: CustomPageValues[];

    return combineLatest([
      this.store.pipe(select(getRules)),
      this.store.pipe(
        select(getDependentPagesConfig),
        filter(configs => isNull(configs) || !!configs?.configs)
      ),
      this.store.select(getNavbarDependentPages),
    ]).pipe(
      first(),
      mergeMap(([rules, configs, pages]) => {
        navbarPages = pages;

        // //TODO: why!!?
        // if (!uiIds.length && !pageIds.length) {
        //   throw new Error();
        // }
        return forkJoin(this.getSaleConceptsPagesByUiIds(params, rules, configs?.configs));
      }),
      map(response => ({ payload: this.convertSalesConceptsArray(response), response })),
      switchMap(({ payload, response }) => {
        const dependentPagesParams = payload.map((item: any) => ({
          uiId: item.config.uiId,
          dependentPages: item.dependentPages.filter(i => navbarPages.some(p => p.uiId === i.uiId)),
        }));
        const dependentPagesFetch$ = fetchDependentPages
          ? this.getSalesConceptDependentPages(dependentPagesParams, navbarPages).pipe(
              tap(dependentPagesData => (pages = dependentPagesData)),
              switchMap(() => this.store.select(getDependentPagesConfig).pipe(first())),
              switchMap(data => {
                const conditions = [
                  this.global.isPresentationExportPdfRoute(),
                  this.global.isSharedPresentation(),
                  !pages,
                  pages?.length === 0,
                  !data?.configs,
                ];
                const condition = conditions.some((item: boolean) => item === true);

                return condition ? of(null) : this.updateDependentPagesConfig(pages, data).pipe(first());
              }),
              map(() => pages)
            )
          : of(null);

        return dependentPagesFetch$.pipe(map(dependentPages => ({ payload, response, dependentPages })));
      }),
      tap(({ response, payload }) => this.dispatchSalesConcepts(response.length, payload)),
      catchError(this.handleSalesConceptsError.bind(this)),
      first()
    );
  }

  private getSaleConceptsPagesByUiIds(
    params: CompileParams[],
    rules: ShortfallFields,
    configs: CustomPagesConfig[]
  ): Observable<CompiledOptions>[] {
    return params.map(item => {
      const { uiId, internalId } = this.getSalesConceptIds(item.uiId);
      const options = { uiId, isSalesConcept: true, internalId, pageId: item._id, tookByFormula: item?.tookByFormula };

      return this.getPage(rules, {}, options, configs).pipe(
        first(),
        catchError(() => of(null))
      );
    });
  }

  private dispatchSalesConcepts(quantity: number, payload: any): void {
    if (quantity === 1) {
      this.store.dispatch(getSalesConceptLoadingSuccess({ payload }));
    } else {
      this.store.dispatch(getSalesConceptsLoadingSuccess({ payload }));
    }
  }

  // TODO: will remove when implement this on the backend. We need to update it on the backend side
  private updateDependentPagesConfig(dependentPages, data): Observable<any> {
    const staticUiIds = [
      'retirement_shortfall',
      'spreadsheet',
      'custom_visualization',
      'cover',
      'target_premium',
      'charges',
      'life_products',
    ];
    const configs = data?.configs.map(config => {
      if (staticUiIds.includes(config.uiId) || /single_policy_/.test(config.uiId)) {
        return config;
      }

      const mappedPages = dependentPages
        .map(item => {
          if (config.uiId === item.parentUiId) {
            const found = config.pages.find(page => page.uiId === item.config.uiId);

            return found ? found : { uiId: item.config.uiId, isSelected: !item.disabled, inserts: item.inserts };
          }
        })
        .filter(Boolean);

      return mappedPages.length ? { ...config, pages: mappedPages } : config;
    });

    return this.store.pipe(
      select(getPresentationLocked),
      first(),
      switchMap((isPresentationLocked: boolean) => {
        if (!isPresentationLocked) {
          return this.apiService
            .putDependentPageConfig(data?.presentationId || this.global.getActivePresentationId, configs)
            .pipe(
              first(),
              tap(data =>
                this.handleDependentPagesConfigSuccess({
                  data: { configs: { ...data?.data?.configs } },
                })
              )
            );
        }

        return of(true);
      })
    );
  }

  public getSalesConceptIds(id: string): CustomPageIds {
    const [uiId, internalId] = id ? id.split('.') : [];

    return { uiId, internalId: Number(internalId) || null };
  }

  public showDependentPageMissedPlansMessage(selectedPageParent: CustomPageValues, configs: PageConfig[]) {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [min] = selectedPageParent?.productsRange;
    const parentConfig = _.find(configs, {
      config: { uiId: selectedPageParent.config.uiId },
    });
    const [countOfValidProducts] = selectedPageParent.eligibleProductsActive
      ? this.validateProductTypes(selectedPageParent, configs)
      : [min];
    const countOfValidCarriers = selectedPageParent.isEligibleForCarriers
      ? this.validateCarriers(selectedPageParent, configs)
      : min;

    return (
      parentConfig.config.isSalesConcept &&
      (parentConfig.config.activePlans.length < min || countOfValidProducts < min || countOfValidCarriers < min)
    );
  }

  public validateCarriers(selectedPage: CustomPageValues, configs: PageConfig[]): number | null {
    if (!selectedPage || !configs) return null;

    const salesConfig = _.find(configs, {
      config: { uiId: selectedPage.config.uiId },
    });

    if (!salesConfig) return null;

    const activeCarriers = [];
    salesConfig.config.activePlans.forEach((activePlan: ActivePlan) => {
      const found = this.global.getCurrentCarrierPlans.find((plan: CareerPlan) => plan.id === activePlan.carrierPlanId);
      activeCarriers.push(found.configjson.metadata.product_type);
    });

    return activeCarriers.length;
  }

  public validateProductTypes(selectedPage: CustomPageValues, configs: PageConfig[]): [number, string] {
    if (!selectedPage || !configs) return [null, ''];

    const productTypes = [];
    const productLabels = [];
    const activeProductTypes = [];

    if (selectedPage.eligibleProductTypes) {
      selectedPage.eligibleProductTypes.forEach(plan => {
        productLabels.push(plan.label);
        productTypes.push(plan.productType);
      });
    }

    const salesConfig = _.find(configs, {
      config: { uiId: selectedPage.config.uiId },
    });

    if (salesConfig) {
      salesConfig.config.activePlans.forEach((activePlan: ActivePlan) => {
        activeProductTypes.push(
          this.global.getCurrentCarrierPlans.find((plan: CareerPlan) => plan.id === activePlan.carrierPlanId).configjson
            .metadata.product_type
        );
      });
    }

    const countOfValidProducts = activeProductTypes.filter(type =>
      [...productTypes, ...productLabels].includes(type)
    ).length;

    const eligibleTypesLabels = productLabels.join(', ');

    return [countOfValidProducts, eligibleTypesLabels];
  }

  public updateChartsConfigsDependentPage(
    event: PinValues[],
    customPagesConfig: CustomPagesConfig[],
    selectedPageUiId: string,
    parentUiId: string,
    PDFPrint = false
  ): boolean {
    const freezeConfig = _.cloneDeep(customPagesConfig);

    if (event && !PDFPrint) {
      const config = this.getDependentPageItemConfig(customPagesConfig, parentUiId, selectedPageUiId);
      this.updateChartsConfigs(event, config);
    }

    return JSON.stringify(freezeConfig) !== JSON.stringify(customPagesConfig);
  }

  private handleDependentPagesConfigSuccess(response: any): Observable<ResponseType> {
    response &&
      this.store.dispatch(
        getDependentPagesConfigLoadingSuccess({
          payload: response.data.configs,
        })
      );

    return response;
  }

  public updateInsertsConfigsDependentPage(
    updatedInserts: InsertConfig[],
    customPagesConfig: CustomPagesConfig[],
    selectedPageUiId: string,
    parentUiId: string
  ) {
    const updatedConfigs = customPagesConfig.map(config =>
      config.uiId === parentUiId ? (() => this.updateInsertsWithSameIds(config, updatedInserts))() : config
    );

    updatedConfigs.forEach(config => {
      if (config.uiId === parentUiId) {
        const hasPageConfig = config.pages.find(page => page.uiId === selectedPageUiId);

        if (!hasPageConfig) {
          const page = { isSelected: true, uiId: selectedPageUiId } as CustomPagesConfig;

          config.pages.push(this.getConfigWithUpdatedInserts<CustomPagesItemConfig>(page, updatedInserts));
        }
      }
    });

    return this.saveCustomPagesConfig(updatedConfigs);
  }

  //TODO: It seems useless of having `updateInsertsConfigsDependentPage` & `updateInsertsConfigsDirectPage` it could be merged in one method.
  // SEE https://assuranceapp.atlassian.net/browse/DAT-7321
  public updateInsertsConfigsDirectPage(
    updatedInserts: InsertConfig[],
    dependentPagesConfig: CustomPagesConfig[],
    selectedPageUiId: string,
    dependentPages?: CustomPagesItemConfig[]
  ) {
    const updatedConfigs = updatedInserts?.length
      ? dependentPagesConfig.map(config =>
          config.uiId === selectedPageUiId ? (() => this.updateInsertsWithSameIds(config, updatedInserts))() : config
        )
      : dependentPagesConfig;

    if (!updatedConfigs.find(config => config.uiId === selectedPageUiId)) {
      updatedConfigs.push({
        addedDate: new Date(),
        uiId: selectedPageUiId,
        inserts: updatedInserts || [],
        pages: dependentPages ? this.updatePagesInsertOnAddingSaleStory(updatedInserts, dependentPages) : [],
      });
    }

    return this.saveCustomPagesConfig(updatedConfigs);
  }

  // TODO:
  // This logic is duplicated from the method above, you need to move
  // the line return this.saveCustomPagesConfig(updatedConfigs) out of bounds
  // from the updateInsertsConfigsDirectPage method
  public updatePagesConfig(inserts: InsertConfig[], pages: CustomPagesConfig[], pageUiId: string): CustomPagesConfig[] {
    const updatedConfigs = inserts?.length
      ? pages.map(config =>
          config.uiId === pageUiId ? (() => this.updateInsertsWithSameIds(config, inserts))() : config
        )
      : pages;

    if (!updatedConfigs.find(config => config.uiId === pageUiId)) {
      updatedConfigs.push({ uiId: pageUiId, inserts: inserts || [], pages: [] });
    }

    return updatedConfigs;
  }

  private updatePagesInsertOnAddingSaleStory(
    inserts: InsertConfig[],
    pages: CustomPagesItemConfig[]
  ): CustomPagesItemConfig[] {
    pages.forEach(page => {
      inserts.forEach(insert => {
        if (page.inserts.length) {
          page.inserts.forEach(childPageInsert => {
            if (childPageInsert.uiId === insert.uiId) {
              childPageInsert.value = insert.value;
            }
          });
        }
      });
    });

    return pages;
  }

  public updateChartsConfigsDirectPage(
    event: PinValues[],
    customPagesConfig: CustomPagesConfig[],
    selectedPageUiId: string,
    PDFPrint = false
  ): boolean {
    const freezeConfig = _.cloneDeep(customPagesConfig);

    if (event && !PDFPrint) {
      const config = this.getCustomPageItemConfig(customPagesConfig, selectedPageUiId);
      this.updateChartsConfigs(event, config);
    }

    return JSON.stringify(freezeConfig) !== JSON.stringify(customPagesConfig);
  }

  public removeAndSaveCustomPagesConfig(configs: CustomPagesConfig[], removedPageUiId: string) {
    const saveConfigs = _.filter(configs, conf => conf.uiId !== removedPageUiId);

    return this.apiService.putDependentPageConfig(this.global.getActivePresentationId, saveConfigs);
  }

  public deleteSaleConcept(presentationId: number, uiId: string) {
    return this.apiService.deleteCustomPageFromPresentation(presentationId, uiId);
  }

  public isAllRendered(chartsMetadata: ExtendedPlaceholderMetadata[]) {
    const chartData = _.filter(chartsMetadata, { isChartPlaceholder: true });

    return _.every(chartData, { rendered: true });
  }

  public saveCustomPagesConfig(configs: CustomPagesConfig[]): Observable<CustomPageResponse> {
    return this.store.pipe(
      select(getPresentationLocked),
      first(),
      switchMap((isPresentationLocked: boolean): Observable<CustomPageResponse | null> => {
        if (this.global.isSharedPresentation()) {
          return this.storeCustomPageConfigs(configs);
        }

        if (!isPresentationLocked) {
          return this.apiService.putDependentPageConfig(this.global.getActivePresentationId, configs);
        }

        return of(null);
      }),
      tap(() => this.handleDependentPagesConfigSuccess({ data: { configs: { configs } } }))
    );
  }

  public storeCustomPageConfigs(customPagesConfig: CustomPagesConfig[]): any {
    sessionStorage.setItem(CUSTOM_PAGE_CONFIGS_SESSION_STORAGE_KEY, JSON.stringify(customPagesConfig));

    return of({
      data: { configs: { configs: customPagesConfig } },
    });
  }

  public splitPageBodyByCharts(htmlBody: string): string[] {
    const insertBeforeReg =
      /<div class="app-chart" style="border:dashed 1px rgba\(0, 0, 0, 0.2\);border-radius:20px">/g;
    const insertAfterReg = /<span class="chart-divider">----chart ends----<\/span><\/div>/g;
    let str = htmlBody.replace(insertBeforeReg, '!!!$&');
    str = str.replace(insertAfterReg, '$&!!!');

    return str.split('!!!');
  }

  getCommonUiIds(params: { uiId: string[] }, configs: PageConfig[]) {
    const configSalesConceptsIds: string[] = [];
    configs.forEach(conf => {
      conf.config.isSalesConcept && configSalesConceptsIds.push(conf.config.uiId);
    });
    const uiIds = params.uiId || [];
    const commonUiIds = [];
    configSalesConceptsIds.forEach(item => {
      const hasTail = uiIds.every(id => this.hasSalesConceptTail(id));

      if (hasTail) {
        uiIds.includes(item) && commonUiIds.push(item);
      } else {
        const { uiId } = this.getSalesConceptIds(item);
        uiIds.includes(uiId) && commonUiIds.push(item);
      }
    });

    return commonUiIds;
  }

  public getExistingSalesConcept(
    params: { uiId: string[] },
    configs: PageConfig[],
    navBarPages?: NavbarCustomPagesData,
    isMapForNavbar?: boolean
  ): Observable<any> {
    const commonUiIds = this.getCommonUiIds(params, configs);
    const pages = commonUiIds.map((uiId: string) => {
      const found = navBarPages?.salesConcepts.find(item => item.config.uiId === uiId);

      return found ? { uiId, _id: found._id, tookByFormula: found?.tookByFormula } : { uiId };
    });

    if (pages.length) {
      return this.getSalesConcepts(pages).pipe(
        switchMap(data => {
          const { salesConceptsResponse } = data;

          return isMapForNavbar && data?.dependentPages.length
            ? this.handlePagesAfterCompilation(data?.dependentPages)
            : of(salesConceptsResponse);
        })
      );
    } else {
      this.store.dispatch(getSalesConceptDependentPagesLoadingSuccess());

      return of(null);
    }
  }

  private handlePagesAfterCompilation(pages: CustomPageValues[] = []): any {
    const dependentPagesUIds = pages.map(page => page.config.uiId);
    const mappedDependentPages = pages.map(page => ({ uiId: page.config.uiId, parentUiId: page.parentUiId }));

    return this.store.select(getNavbarCustomPages).pipe(
      tap((data: NavbarCustomPagesData) => {
        const filteredDependentPages: CustomPageValues[] = data.fullData
          .reduce((acc: CustomPageValues[], item: any) => [...acc, ...item.dependentPages], [])
          .filter(dp => dependentPagesUIds.includes(dp.uiId))
          .map((page: CustomPageValues) => {
            const found = mappedDependentPages.find(item => item.parentUiId === page.parentUiId);

            return {
              ...page,
              parentUiId: found.parentUiId,
              isDependentPage: true,
              label: page.label, //page.name,
              config: { uiId: page.uiId },
              layout: page.customFields.layout,
              disabled: page.customFields.disabled,
              order: page.customFields.order,
              isPageLockedAndEnabled: page.customFields.isPageLockedAndEnabled,
            };
          });

        const groupedByParentUiId = groupBy(filteredDependentPages, 'parentUiId');
        const salesConcepts = data.salesConcepts.map((concept: CustomPageValues) => {
          return {
            ...concept,
            dependentPages: groupedByParentUiId[concept.config.uiId],
          };
        });

        this.store.dispatch(
          setNavbarCustomPages({ payload: { ...data, salesConcepts, dependentPages: filteredDependentPages } })
        );
      })
    );
  }

  public getDependentPagesConfig(presentationId: number, isPdf: boolean) {
    const isSharedPresentation = this.global.isSharedPresentation();
    const storageCustomPageConfigs = isPdf ? sessionStorage.getItem(CUSTOM_PAGE_CONFIGS_SESSION_STORAGE_KEY) : null;
    this.store.dispatch(getDependentPagesConfigLoadingPending());

    return (
      isSharedPresentation
        ? this.handleSharedPresentation(storageCustomPageConfigs)
        : this.apiService.getDependentPageConfig(presentationId)
    ).pipe(
      first(),
      map(this.handleDependentPagesConfigSuccess.bind(this)),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      catchError(this.handleDependentPagesConfigError.bind(this))
    );
  }

  private handleSharedPresentation(storageCustomPageConfigs: string | null) {
    return this.apiService.getDependentPages().pipe(
      map(response => {
        const configs = response.data.configs?.configs;

        if (!configs) {
          return response;
        }

        const parsedStorageConfigs = JSON.parse(storageCustomPageConfigs);
        const restStorageConfigs = parsedStorageConfigs ? _.differenceBy(parsedStorageConfigs, configs, 'uiId') : [];
        const configsToStore = storageCustomPageConfigs
          ? [...configs, ...restStorageConfigs].map(config => {
              const storageConfig = parsedStorageConfigs?.find(
                parsedStorageConfig => parsedStorageConfig.uiId === config.uiId
              );

              if (!storageConfig) {
                return config;
              }

              return {
                ...config,
                charts: this.getSharedConfigForCustomPage(config.charts, storageConfig.charts),
                inserts: this.getSharedConfigForCustomPage(config.inserts, storageConfig.inserts),
                pages: config.pages?.map(page => {
                  const storageConfigPage = storageConfig.pages?.find(
                    storageConfigPage => storageConfigPage.uiId === page.uiId
                  );

                  return {
                    ...page,
                    charts: this.getSharedConfigForCustomPage(page.charts, storageConfigPage?.charts || []),
                    inserts: this.getSharedConfigForCustomPage(page.inserts, storageConfigPage?.inserts || []),
                  };
                }),
              };
            })
          : configs;

        this.storeCustomPageConfigs(configsToStore);

        return {
          data: {
            configs: {
              ...response.data.configs,
              configs: configsToStore,
            },
          },
        };
      })
    );
  }

  private getSharedConfigForCustomPage(
    item: Array<CustomPageItemChart | InsertConfig> = [],
    storageItem: Array<CustomPageItemChart | InsertConfig> = []
  ): CustomPageItemChart[] {
    return [...item, ...storageItem].reduce((res, element) => {
      return res.find(resElement => resElement.uiId === element.uiId)
        ? [...res.filter(resElement => resElement.uiId !== element.uiId), element]
        : [...res, element];
    }, []);
  }

  public concatSalesConceptIds(id: string, internalId: number | string): string {
    return internalId ? id.concat('.', `${internalId}`) : id;
  }

  public buildBodyToSend(data) {
    return {
      pageName: data.label,
      internalId: data.internalId,
      config: { ...data.config, isSalesConcept: true, isParentEnabled: true },
    };
  }

  getPages(params: CustomHttpParams, options?: CompiledOptions): Observable<CompiledOptions> {
    let configOfAddedDate;

    return forkJoin([
      this.store.pipe(
        select(getDependentPagesConfig),
        filter(configs => isNull(configs) || !!configs?.configs),
        first(),
        map(configs => ({ configs, config: this.getAddedDateForCompilation(configs?.configs, options) })),
        switchMap((data: CustomPagesMappedConfigs) => {
          const { configs, config } = data;
          configOfAddedDate = config;

          if (configOfAddedDate?.addedDate) {
            Object.assign(params, { addedDate: configOfAddedDate.addedDate });
          }

          return this.apiService
            .getCustomPagesInserts(params, this.global.isSharedPresentation())
            .pipe(map(data => [configs, data]));
        })
      ),
      this.store.pipe(select(getRules), first()),
      this.store.pipe(select(getPresentationConfigs), first()),
      this.store.pipe(select(getGraphValue), first()),
      this.store.pipe(select(getPresentationId), first()),
    ]).pipe(
      mergeMap(([configsAndInserts, rules, configs, graphValue, presentationId]) => {
        const [customPagesConfigs, insertsByUiId] = configsAndInserts;
        const data = this.getDataForCompiledPages(
          rules,
          options,
          insertsByUiId,
          customPagesConfigs?.configs,
          configs,
          graphValue,
          null,
          configOfAddedDate?.addedDate
        );

        return this.apiService.getCompiledPages(params, data, this.global.isSharedPresentation(), presentationId);
      }),
      map(response => ({
        uiId: options?.uiId,
        internalId: options?.internalId,
        response: Array.isArray(response.data.data) ? response.data.data : [response.data.data],
      }))
    );
  }

  getPagesByPageId(pageIds: CompiledOptions[], options: CompiledOptions): Observable<CompiledOptions> {
    return forkJoin([
      this.store.pipe(
        select(getDependentPagesConfig),
        filter(configs => isNull(configs) || !!configs?.configs),
        first(),
        switchMap(configs => {
          const params = pageIds.map(item => item.pageId);

          return this.apiService
            .getCustomPagesInsertsByPageIds({ pageids: params }, this.global.isSharedPresentation())
            .pipe(map(data => [configs, data]));
        })
      ),
      this.store.pipe(select(getRules), first()),
      this.store.pipe(select(getPresentationConfigs), first()),
      this.store.pipe(select(getGraphValue), first()),
      this.store.pipe(select(getPresentationId), first()),
    ]).pipe(
      mergeMap(([configsAndInserts, rules, configs, graphValue, presentationId]) => {
        const [customPagesConfigs, insertsByUiId] = configsAndInserts;

        const requests = pageIds.map(item => {
          const data = this.getDataForCompiledPages(
            rules,
            options,
            insertsByUiId,
            customPagesConfigs?.configs,
            configs,
            graphValue,
            null
          );

          return this.apiService
            .getCompiledPage(
              item.uiId,
              data,
              this.global.isSharedPresentation(),
              {},
              presentationId,
              item?.pageId,
              item?.tookByFormula
            )
            .pipe(map(data => data.data.data));
        });

        return forkJoin(requests);
      }),
      map(response => ({ uiId: options?.uiId, internalId: options?.internalId, response }))
    );
  }

  getPage<T = CustomPage>(
    rules: ShortfallFields,
    params: CustomHttpParams,
    options: CompiledOptions,
    customPagesConfigs: CustomPagesConfig[],
    insertsByUiId?: { uiId: string; inserts: T[] }[],
    parentOptions?: CompiledOptions,
    settingsProductOrder?: number[]
  ): Observable<CompiledOptions> {
    const config = this.getAddedDateForCompilation(customPagesConfigs, options);
    let insertsParams = { ...params };

    if (options?.tookByFormula) {
      insertsParams = { ...insertsParams, pageids: options.pageId };
    } else {
      insertsParams = { ...insertsParams, ...config, uiId: options.uiId };
    }

    const insertsReq = options?.tookByFormula
      ? this.apiService.getCustomPagesInsertsByPageIds(insertsParams, this.global.isSharedPresentation())
      : this.apiService.getCustomPagesInserts(insertsParams, this.global.isSharedPresentation());

    return (insertsByUiId ? of(insertsByUiId) : insertsReq).pipe(
      withLatestFrom(
        this.store.pipe(select(getPresentationConfigs)),
        this.store.pipe(select(getGraphValue)),
        this.store.pipe(select(getPresentationId))
      ),
      switchMap(([insertsByUiId, configs, graphValue, presentationId]) => {
        const data = this.getDataForCompiledPages(
          rules,
          options,
          insertsByUiId,
          customPagesConfigs,
          configs,
          graphValue,
          parentOptions,
          config?.addedDate,
          settingsProductOrder
        );

        return this.apiService.getCompiledPage(
          options.uiId,
          data,
          this.global.isSharedPresentation(),
          params,
          presentationId,
          options?.pageId,
          options?.tookByFormula
        );
      }),
      map(response => ({
        uiId: (parentOptions || options)?.uiId,
        internalId: (parentOptions || options)?.internalId,
        response: Array.isArray(response.data.data) ? response.data.data : [response.data.data],
      }))
    );
  }

  public getCompiledInserts(pageUiId: string, settingsProductOrder?: number[]): Observable<any[]> {
    return combineLatest([
      this.store.pipe(select(getRules)),
      this.store.pipe(
        select(getDependentPagesConfig),
        filter(configs => isNull(configs) || !!configs?.configs)
      ),
      this.store.select(getNavbarCustomPages),
    ]).pipe(
      mergeMap(([rules, configs, pages]) => {
        const info = this.getSalesConceptIds(pageUiId);
        const navItemConcept = pages.salesConcepts.find(item => item.config.uiId === pageUiId);
        const options: CompiledOptions = {
          isSalesConcept: true,
          ...info,
          pageId: navItemConcept?._id,
          tookByFormula: navItemConcept?.tookByFormula,
        };

        return this.getPage(rules, {}, options, configs?.configs, null, null, settingsProductOrder).pipe(
          catchError(err => {
            if (err) {
              this.store.dispatch(getSalesConceptsLoadingFailure());

              return of(null);
            }
          })
        );
      }),
      map(data => this.convertInserts(data?.response[0]?.inserts)),
      first()
    );
  }

  private getDataForCompiledPages(
    rules: ShortfallFields = {},
    options: CompiledOptions = {},
    insertsByUiId: InsertByCustomPageUUID[] = [],
    customPagesConfigs: CustomPagesConfig[] = [],
    configs: PageConfig[] = [],
    graphValue: XAxisSourceType,
    parentOptions?,
    addedDate?: TimeDate,
    settingsProductOrder?: number[]
  ): CompilePageOptions {
    const { products } = this.getProductsInfo(
      parentOptions || options,
      configs,
      options.isDependentPage,
      settingsProductOrder
    );
    const productsOrders = products.map((product, index) => [product.id, index]);
    const productSelectors = this.getProductSelectors(
      parentOptions || options,
      insertsByUiId,
      customPagesConfigs,
      products
    );

    const compileOptions: CompilePageOptions = {
      compileData: {
        productsOrders,
        variables: this.getVariables(parentOptions || options, insertsByUiId, customPagesConfigs),
        dropdowns: this.getDropdowns(parentOptions || options, insertsByUiId, customPagesConfigs),
        selectedDropdownIndex: this.getSelectedDropdownIndex(
          parentOptions || options,
          insertsByUiId,
          customPagesConfigs
        ),
        productSelector: productSelectors,
        selectedProductIndex: productSelectors?.selected_product,
        tabs: this.getTabInsert(parentOptions || options, insertsByUiId, customPagesConfigs),
        presentation: this.getPresentationInfo(),
        assumptions: this.getAssumptionsInfo(rules, configs),
        profile: this.getProfileInfo(),
        globalConfig: this.getGlobalConfig(configs, graphValue),
        dropdownOptions: this.getDropDownOptionsForPrecompile(parentOptions || options, insertsByUiId),
      },
      compileSettings: {
        paths: COMPILE_SETTINGS,
        calculatedPaths: CALCULATED_SETTINGS,
        additionalDataInsertTypes: ADDITIONAL_DATA_INSERT_TYPES,
        precompilePaths: PrecompilePaths,
      },
    };

    if (addedDate) {
      compileOptions.addedDate = addedDate;
    }

    return compileOptions;
  }

  private getAddedDateForCompilation(
    configs: CustomPagesConfig[],
    options: CompiledOptions
  ): { addedDate: string } | null {
    let config;

    if (options?.tookByFormula) {
      return null;
    }

    if (configs) {
      config = configs.find(item => {
        if (options && options.uiId && options.internalId) {
          return item.uiId === `${options.uiId}.${options.internalId}`;
        }
      });
    }

    return config && config.addedDate ? { addedDate: config.addedDate } : null;
  }

  private getPresentationInfo(): Presentation {
    const isShared = this.global.isSharedPresentation();
    const paths = this.location.pathname.split('/');
    const { shareableToken, ...presentation } = this.global.getPresentation;
    const presentationId = isShared ? shareableToken : presentation.id;
    const result = {
      ...presentation,
      id: presentationId,
      presentationInitPage: isShared ? PresentationRoutes.sharedPresentation : PresentationRoutes.presentation,
      viewType: paths.includes(PresentationRoutes.view) ? PresentationRoutes.view : PresentationRoutes.setup,
    };

    if (!shareableToken && !isShared) {
      this.apiService.getSharedToken(presentationId).subscribe((token: string) => {
        result.shareableLink = this.formShareableLink(token);
      });
    } else {
      result.shareableLink = this.formShareableLink(shareableToken);
    }

    return result;
  }

  private formShareableLink(shareableToken: string): string {
    return `${location.origin}/shared-presentation/${shareableToken}?linkSource=fromPDF`;
  }

  private getVariables(
    options: CompiledOptions,
    insertsByUiId: InsertByCustomPageUUID[],
    customPagesConfigs: CustomPagesConfig[]
  ): Record<string, number> {
    return this.getCompileOptionsItemByType<number>(
      options,
      insertsByUiId,
      customPagesConfigs,
      INSERT_TYPE.variable,
      'placeholderDefaultValue'
    );
  }

  private getDropdowns(
    options: CompiledOptions,
    insertsByUiId: InsertByCustomPageUUID[],
    customPagesConfigs: CustomPagesConfig[]
  ): Record<string, string> {
    return this.getCompileOptionsItemByType<string>(
      options,
      insertsByUiId,
      customPagesConfigs,
      INSERT_TYPE.dropdown,
      'placeholderSelectedValue'
    );
  }

  private getSelectedDropdownIndex(
    options: CompiledOptions,
    insertsByUiId: InsertByCustomPageUUID[],
    customPagesConfigs: CustomPagesConfig[]
  ): Record<string, number> {
    return this.getCompileOptionsItemByType<number>(
      options,
      insertsByUiId,
      customPagesConfigs,
      INSERT_TYPE.dropdown,
      'placeholderSelectedValue',
      undefined,
      true
    );
  }

  private getProductSelectors(
    options: CompiledOptions,
    insertsByUiId: InsertByCustomPageUUID[],
    customPagesConfigs: CustomPagesConfig[],
    products: CareerPlan[]
  ): Record<string, number> {
    return this.getCompileOptionsItemByType<number>(
      options,
      insertsByUiId,
      customPagesConfigs,
      INSERT_TYPE.productSelector,
      'placeholderSelectedProduct',
      products
    );
  }

  private getTabInsert(
    options: CompiledOptions,
    insertsByUiId: InsertByCustomPageUUID[],
    customPagesConfigs: CustomPagesConfig[]
  ): Record<string, number> {
    return this.getCompileOptionsItemByType<number>(
      options,
      insertsByUiId,
      customPagesConfigs,
      INSERT_TYPE.tab,
      'placeholderSelectedTab'
    );
  }

  private getDropDownOptionsForPrecompile(
    options,
    insertsByPages: InsertByCustomPageUUID[]
  ): Record<string, (string | number)[]> {
    const insertsByPage = insertsByPages.find((item: InsertByCustomPageUUID) => item.uiId === options.uiId);

    return (insertsByPage?.inserts || [])
      .filter(insert => insert.metadata.insertType === INSERT_TYPE.dropdown)
      .reduce((acc: Record<string, (string | number)[]>, insert: Insert) => {
        acc[insert.metadata.placeholderKey] = insert.metadata.dropdownOptions.map(option => option.value);

        return acc;
      }, {});
  }

  // TODO: since we now already have a compilation for individual pages,
  // and not for all, this code can be simplified by excluding some checks
  private getCompileOptionsItemByType<T>(
    options: CompiledOptions,
    insertsByUiId: InsertByCustomPageUUID[],
    customPagesConfigs: CustomPagesConfig[],
    insertType: InsertType,
    prop: string,
    products?: CareerPlan[],
    returnIndex = false
  ): Record<string, T> {
    const parentUiId =
      options.uiId && options.internalId ? this.concatSalesConceptIds(options.uiId, options.internalId) : null;

    // TODO: need to refact
    return insertsByUiId.reduce((res, pageInserts) => {
      if (!pageInserts.inserts?.length) return res;

      const keys = {};
      let editedInserts: InsertConfig[];

      if (parentUiId) {
        const salesConcept = customPagesConfigs.find(config => config.uiId === parentUiId);
        editedInserts = (
          options.uiId === pageInserts.uiId
            ? salesConcept
            : salesConcept?.pages.find(page => page.uiId === pageInserts.uiId)
        )?.inserts;
      } else {
        for (const config of customPagesConfigs) {
          if (config.uiId === pageInserts.uiId) {
            editedInserts = config.inserts;

            break;
          }

          const dependentPage = config.pages.find(page => page.uiId === pageInserts.uiId);

          if (dependentPage) {
            editedInserts = dependentPage.inserts;

            break;
          }
        }
      }

      for (const insert of pageInserts.inserts) {
        if (insert.metadata.insertType !== insertType) {
          continue;
        }

        const found = editedInserts?.find(editedInsert => editedInsert.uiId === insert.metadata.id);
        // let value = found?.value || found?.value === 0 ? found.value : insert.metadata[prop];
        let value = found && found.hasOwnProperty('value') ? found.value : insert.metadata[prop];

        if (insert.metadata.insertType === INSERT_TYPE.productSelector) {
          // value = products[Number(value)] ? Number(value) : 0;
          value =
            typeof value === 'string' && value.startsWith('{{') && value.endsWith('}}')
              ? value
              : products[Number(value)]
              ? Number(value)
              : 0;
        }

        if (insert.metadata.insertType === INSERT_TYPE.dropdown && returnIndex) {
          let selectedIndex = found?.index;

          selectedIndex = isDefined(selectedIndex)
            ? selectedIndex
            : insert.metadata.dropdownOptions.findIndex(
                option => option.value === (value || insert.metadata.placeholderSelectedValue)
              );

          value = value?.startsWith('{{') && value?.endsWith('}}') ? value : selectedIndex;
        }

        keys[insert.metadata.placeholderKey] = value;
      }

      return { ...res, ...keys };
    }, {});
  }

  private getGlobalConfig(configs: PageConfig[], graphValue: XAxisSourceType): PresentationConfig {
    const globalConfig = configs[0];

    if (!globalConfig) {
      return {} as PresentationConfig;
    }

    return {
      ...globalConfig,
      config: {
        ...globalConfig.config,
        xAxisSource: graphValue,
      },
    } as any;
  }

  private getProductsInfo(
    options: CompiledOptions,
    configs: PageConfig[],
    isDependentPage = false,
    settingsProductOrder?: number[]
  ) {
    const products = this.global.getCurrentCarrierPlans ?? [];

    if ((options?.isSalesConcept || isDependentPage) && options?.uiId) {
      const salesConcept = _.find(configs, {
        config: {
          uiId: options.internalId ? this.concatSalesConceptIds(options.uiId, options.internalId) : options.uiId,
        },
      });

      let activeProducts;

      if (settingsProductOrder?.length) {
        activeProducts = settingsProductOrder.map(id => products.find(product => product.id === id));
      } else if (salesConcept) {
        activeProducts = _.orderBy(salesConcept.config.activePlans, activePlan => activePlan.order).map(activePlan =>
          //@ts-ignore
          products.find(product => product.id === activePlan.carrierPlanId)
        );
      } else {
        activeProducts = this.orderProductsBy(products);
      }

      return {
        products: this.extendProductsWithPdfLink(activeProducts),
      };
    }

    return {
      products: this.extendProductsWithPdfLink(this.orderProductsBy(products)),
    };
  }

  private extendProductsWithPdfLink(products): CareerPlan[] {
    if (!products) return null;

    return products.map(product => {
      if (product?.pdfIllustration?.linkHash) {
        return {
          ...product,
          pdfIllustration: {
            ...product.pdfIllustration,
            link: this.global.PDFLinkURL(product.id, product.pdfIllustration.linkHash),
          },
        };
      }

      return product;
    });
  }

  private getProfileInfo() {
    if (!this.authService.isLogged) {
      return {};
    }

    return {
      user: {
        firstName: this.userService.user.firstName,
        lastName: this.userService.user.lastName,
      },
      organization: {
        name: this.userService.organization && this.userService.organization.name,
      },
    };
  }

  private getAssumptionsInfo(rules, configs: PageConfig[]) {
    const retirementConfig = configs.find((item: any) => item.config.uiId === 'retirement_shortfall');

    if (retirementConfig?.config.shortfallSettings?.retirementAge) {
      return retirementConfig.config.shortfallSettings;
    }

    const assumptions = {};
    RETIREMENT_SHORTFALL_SETTINGS_FIELDS.forEach(ruleKey => (assumptions[ruleKey] = rules[ruleKey]));

    return assumptions;
  }

  private getSalesConceptDependentPages(data: DependentPagesParams[], pages: CustomPageValues[]): Observable<any> {
    const dps = _.uniqBy(data, 'uiId').reduce((acc, item: DependentPagesParams) => {
      const info = this.getSalesConceptIds(item.uiId);
      const ids = item.dependentPages.map(page => {
        const found = pages.find(item => item.config.uiId === page.uiId);

        return {
          uiId: page.uiId,
          pageId: found?._id,
          tookByFormula: found?.tookByFormula,
        };
      });

      const uiIds = ids.filter(page => !page?.tookByFormula).map(page => page.uiId);
      const pageIds = ids.filter(page => page?.tookByFormula);

      return [...acc, this.getDependentPagesByUiIds(uiIds, info), this.getDependentPagesByPageIds(pageIds, info)];
    }, []);

    return data?.length
      ? forkJoin(dps).pipe(
          catchError(err => (err && err.status === 404 ? of(null) : throwError(err))),
          map(data => this.handleDependentPageSuccess(data)),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          catchError(this.handleDependentPagesError.bind(this))
        )
      : of(null);
  }

  private getDependentPagesByUiIds(uiIds: string[], info: CustomPageIds) {
    return !uiIds.length ? of(null) : this.getPages({ uiId: uiIds }, { ...info, isSalesConcept: true });
  }

  private getDependentPagesByPageIds(pageIds: CompiledOptions[], info: CustomPageIds) {
    return !pageIds.length ? of(null) : this.getPagesByPageId(pageIds, { ...info, isSalesConcept: true });
  }

  private getCustomPageItemConfig(customPagesConfig: CustomPagesConfig[] = [], uiId: string): CustomPagesConfig {
    let config = _.find(customPagesConfig, {
      uiId: uiId,
    });

    if (!config) {
      config = {
        uiId,
        pages: [],
      } as CustomPagesConfig;
      customPagesConfig.push(config);
    }

    return config;
  }

  private getDependentPageItemConfig(
    customPagesConfig: CustomPagesConfig[] = [],
    parentUiId: string,
    uiId: string
  ): CustomPagesItemConfig {
    const parentConfig = _.find(customPagesConfig, {
      uiId: parentUiId,
    });
    let config = _.find(parentConfig.pages, {
      uiId,
    }) as CustomPagesItemConfig;

    if (!config) {
      config = {
        uiId: uiId,
        pages: [],
        isSelected: true,
      } as CustomPagesConfig;
      parentConfig.pages.push(config);
    }

    return config;
  }

  private orderProductsBy(products: CareerPlan[], field = 'order'): CareerPlan[] {
    return _.orderBy(products, field);
  }

  public convertEndPagesArray(endPages: CustomPageValues[]): CustomPageValues[] {
    const mappedEndPages = endPages.map((endPage: any) => {
      return {
        htmlBody: endPage.customFields.htmlBody,
        cssBody: endPage.customFields.cssBody,
        layout: endPage.customFields.layout,
        config: {
          uiId: endPage.uiId,
        },
        carrierUiIds: endPage.customFields.carrierUiIds,
        order: endPage.customFields.order,
        label: endPage.label,
        isEndPage: true,
        portal: endPage.portal,
        insertIds: endPage.customFields.insertIds,
        inserts: this.convertInserts(endPage.inserts),
        hideOnPDF: endPage.customFields.hideOnPDF,
        orderRank: endPage.customFields.orderRank,
        isBulkEditOfVariables: endPage.customFields.isBulkEditOfVariables,
      };
    });

    return this.orderEndPagesByRank(mappedEndPages);
  }

  private orderEndPagesByRank(mappedEndPages: CustomPageValues[]): CustomPageValues[] {
    return mappedEndPages.sort((a, b) => a.orderRank - b.orderRank);
  }

  public convertDependentPagesArray(pages: any) {
    const convertedPages = [];

    if (!Array.isArray(pages)) {
      pages = [pages];
    }

    for (const page of pages) {
      page &&
        convertedPages.push(
          ...page.response.map(item => {
            let parentUiId = null;

            if (page.uiId && page.internalId) {
              parentUiId = this.concatSalesConceptIds(page.uiId, page.internalId);
            }

            return {
              htmlBody: item.customFields.htmlBody,
              createdAt: item.createdAt,
              cssBody: item.customFields.cssBody,
              layout: item.customFields.layout,
              config: {
                uiId: item.uiId,
              },
              parentUiId,
              order: item.customFields.order,
              label: item.label,
              hasNewProductsOrder: item?.hasNewProductsOrder || false,
              newProductsOrder: item?.newProductsOrder,
              isDependentPage: true,
              portal: item.portal,
              insertIds: item.customFields.insertIds,
              isPageLockedAndEnabled: item.customFields.isPageLockedAndEnabled,
              inserts: this.convertInserts(item.inserts),
              disabled: item.customFields.disabled,
              versionName: item.versionName,
              isBulkEditOfVariables: item.customFields.isBulkEditOfVariables,
            };
          })
        );
    }

    return convertedPages;
  }

  public gatherInsertsCalculatedData(customPage: CustomPageValues): Record<string, number[]>[] {
    return customPage?.inserts?.reduce((res, insert: CustomPageInsert) => {
      if (insert.config.calculable && Array.isArray(insert.calculatedData)) {
        const calculatedRes = [...res];

        insert.calculatedData.forEach((calculatedData: any, index: number) => {
          calculatedData.forEach(item => {
            if (item) {
              const placeholderKey = item.placeholderKey;
              const data = item.data;
              placeholderKey &&
                data &&
                (calculatedRes[index] = {
                  ...(calculatedRes[index] ?? {}),
                  [placeholderKey]: data,
                });
            }
          });
        });

        return calculatedRes;
      }

      return res;
    }, []);
  }

  public convertInserts(inserts: Insert[]): CustomPageInsert[] {
    if (!inserts || !inserts.length) {
      return [];
    }

    return inserts.map((insert: Insert) => ({
      config: {
        uiId: insert.metadata.id,
        editable: insert.editable,
        calculable: insert.calculable,
      },
      filesLinks: insert.metadata?.filesLinks,
      metadata: insert.metadata,
      calculatedData: insert?.calculatedData ? insert.calculatedData : null,
    }));
  }

  private handleEndPagesError(error: any): void {
    this.store.dispatch(getEndPagesLoadingFailure());
    throwError(`${error.status} - ${error.statusText}`);
  }

  handleSalesConceptSuccess(response: any) {
    const payload = this.convertSalesConceptsArray(response);
    this.store.dispatch(
      getSalesConceptLoadingSuccess({
        payload,
      })
    );

    return { response, payload };
  }

  public compileCustomPages(isCompileEndPage = true): Observable<unknown> {
    return this.store.pipe(
      select(getDependentPermissions),
      withLatestFrom(this.store.pipe(select(getPresentationConfigs)), this.store.select(getNavbarCustomPages)),
      switchMap(([dependentPermissions, configs, navbarPages]) => {
        const compiledRequiredPage = dependentPermissions.requiredEndPages
          ? this.getCarrierEndPages({ uiId: dependentPermissions.requiredEndPages, labels: ['requiredpage'] })
          : this.getCarrierEndPages(null);

        return combineLatest([
          isCompileEndPage ? compiledRequiredPage : of(null),
          dependentPermissions.salesConcepts
            ? //TODO: Done
              this.getExistingSalesConcept({ uiId: dependentPermissions.salesConcepts }, configs, navbarPages, true)
            : of(true),
        ]);
      }),
      first()
    );
  }

  private handleSalesConceptsError(error: any): Observable<any> {
    this.store.dispatch(getSalesConceptsLoadingFailure());

    return throwError(() => new Error(`${error.status} - ${error.statusText}`));
  }

  public handleDependentPageSuccess(response: any): ResponseType[] {
    if (response.length) {
      const pages = _.sortBy(this.convertDependentPagesArray(response), ['createdAt']);
      this.store.dispatch(
        getDependentPageLoadingSuccess({
          payload: pages,
        })
      );
      this.store.dispatch(getSalesConceptDependentPagesLoadingSuccess());

      return pages;
    }
  }

  private handleDependentPagesError(error: any): void {
    this.store.dispatch(getDependentPagesLoadingFailure());
    throwError(`${error.status} - ${error.statusText}`);
  }

  private getConfigWithUpdatedInserts<T extends { inserts?: InsertConfig[] }>(
    config: T,
    updatedInserts: InsertConfig[] = []
  ): T {
    return {
      ...config,
      inserts: config.inserts
        ? [
            ...config.inserts.filter(
              insert => !updatedInserts.find(updatedInsert => insert.uiId === updatedInsert.uiId)
            ),
            ...updatedInserts,
          ]
        : updatedInserts,
    };
  }

  private updateChartsConfigs(event: PinValues[], config: CustomPagesItemConfig) {
    event.forEach(item => {
      const newConfig = {
        uiId: item.chartKey,
        pinValue: item.x,
      };
      config.charts || (config.charts = []);
      const chartConfig = _.find(config.charts, {
        uiId: item.chartKey,
      });

      chartConfig ? _.merge(chartConfig, newConfig) : config.charts.push(newConfig);
    });
  }

  private updateInsertsWithSameIds(
    updatedConfig: CustomPagesConfig,
    updatedInserts: InsertConfig[]
  ): CustomPagesConfig {
    const config = cloneDeep(updatedConfig);

    if (config.inserts) {
      config.inserts = [
        ...config.inserts.filter(insert => !updatedInserts.find(updatedInsert => insert.uiId === updatedInsert.uiId)),
        ...updatedInserts,
      ];
    }

    if (config.pages.length) {
      config.pages.forEach(page => {
        if (page.inserts && page.inserts.length) {
          page.inserts = [
            ...page.inserts.filter(insert => !updatedInserts.find(updatedInsert => insert.uiId === updatedInsert.uiId)),
            ...updatedInserts,
          ];
        } else {
          page.inserts = updatedInserts;
        }
      });
    }

    return config;
  }

  private handleDependentPagesConfigError(error: any): void {
    this.store.dispatch(getDependentPagesConfigLoadingFailure());
    throwError(`${error.status} - ${error.statusText}`);
  }

  public getInsertsOfDependPages(uiIds: string[]): Observable<InsertByCustomPageUUID[]> {
    const requests = uiIds.map(uiId => this.apiService.getCustomPagesInserts({ uiId }));

    return requests.length === 0
      ? of([])
      : forkJoin(requests).pipe(
          map(result => {
            return result.reduce((acc, value) => {
              acc.push(...value);

              return acc;
            }, []);
          })
        );
  }

  public getCompileData(params: { uiId: string }, options: CompiledOptions): Observable<ArrayBuffer> {
    let configOfAddedDate;

    return forkJoin([
      this.store.pipe(
        select(getDependentPagesConfig),
        filter(configs => isNull(configs) || !!configs?.configs),
        first(),
        map(configs => {
          return {
            configs,
            config: this.getAddedDateForCompilation(configs?.configs, options),
          };
        }),
        switchMap((data: CustomPagesMappedConfigs) => {
          const { configs, config } = data;
          configOfAddedDate = config;

          if (configOfAddedDate?.addedDate) {
            Object.assign(params, { addedDate: configOfAddedDate.addedDate });
          }

          return this.apiService
            .getCustomPagesInserts(params, this.global.isSharedPresentation())
            .pipe(map(data => [configs, data]));
        })
      ),
      this.store.pipe(select(getRules), first()),
      this.store.pipe(select(getPresentationConfigs), first()),
      this.store.pipe(select(getGraphValue), first()),
    ]).pipe(
      switchMap(([configsAndInserts, rules, configs, graphValue]) => {
        const [customPagesConfigs, insertsByUiId] = configsAndInserts;
        const data = this.getDataForCompiledPages(
          rules,
          options,
          insertsByUiId,
          customPagesConfigs?.configs,
          configs,
          graphValue,
          null,
          configOfAddedDate?.addedDate
        );

        return this.apiService.downloadMetricsForSalesPage(params.uiId, data, this.global.getActivePresentationId);
      })
    );
  }

  getDataSourceLabels(): Observable<DataSourcesConfig[]> {
    return this.apiService.getDataSourcesConfig();
  }

  getIsTookByFormula(
    page: CustomPageValues,
    salesConcepts: CustomPageValues[],
    dependentPages: CustomPageValues[]
  ): boolean {
    const pages = page?.isDependentPage ? dependentPages : salesConcepts;
    const found = pages?.find(item => item.config.uiId === page.config.uiId);

    return found?.tookByFormula;
  }

  // TODO: need to clear code when find another way to compile pages
  // checkIfNeedToRecompilePage(
  //   customPagesConfig: CustomPagesConfig[],
  //   parentUiId: string,
  //   updatedInsert: InsertConfig[],
  //   selectedPageUiId?: string
  // ): any {
  //   const saleConceptConfig = customPagesConfig.find(config => config.uiId === parentUiId);
  //
  //   return of(saleConceptConfig).pipe(
  //     filter((config: CustomPagesConfig) => config.pages.length > 0),
  //     map((config: CustomPagesConfig) => this.checkIfReuseInsertsAreUsed(config, selectedPageUiId, updatedInsert)),
  //     filter((pages: CustomPagesItemConfig[]) => pages.length > 0),
  //     switchMap((pages: CustomPagesItemConfig[]) => {
  //       const { uiId, internalId } = this.getSalesConceptIds(saleConceptConfig.uiId);
  //
  //       return this.getPages({ uiId: pages.map(page => page.uiId) }, { uiId, isSalesConcept: true, internalId });
  //     })
  //   );
  // }
  //
  // private checkIfReuseInsertsAreUsed(
  //   config: CustomPagesConfig,
  //   selectedPageUiId: string,
  //   updatedInserts: InsertConfig[]
  // ): CustomPagesItemConfig[] {
  //   const filteredBySelectedDepPage = config.pages.filter(page => page.uiId !== selectedPageUiId);
  //   const copied = [...filteredBySelectedDepPage];
  //
  //   return filteredBySelectedDepPage.filter((page: CustomPagesItemConfig) => {
  //     const inSaleConceptAndInUpdatedInserts = page.inserts.filter((insert, index) => {
  //       updatedInserts.some(item => item.uiId === insert.uiId) &&
  //         (config.inserts.some(item => item.uiId === insert.uiId) ||
  //           copied[index].inserts.some(item => item.uiId === insert.uiId));
  //     });
  //
  //     return inSaleConceptAndInUpdatedInserts.length > 0;
  //   });
  // }
}

import { Injectable } from '@angular/core';

import { ceil, cloneDeep, filter, first, get, indexOf, isArray, isNil, last, max, maxBy, min, some } from 'lodash-es';

import {
  CareerPlan,
  ChartData,
  ChartDataCreationParams,
  ChartType,
  DataTarget,
  LinesChartType,
  MappedChartData,
  Metric,
  MetricInterpolate,
  MetricStepType,
  PlanData,
  PointData,
  XAxisSourceType,
} from '@core/model';
import { CALCULATED_DATA_TARGET, ChartTypes } from '@core/enums';

@Injectable()
export class ChartDataService {
  private dataY: {
    [key in ChartType | LinesChartType]?: (
      value: PointData,
      index: number,
      indexParent: number,
      yMin: number,
      yMax: number,
      data: ChartData[]
    ) => void;
  } = {
    [ChartTypes.multiBarChart]: this.setStackedY,
    [ChartTypes.lineChart]: this.getLineY,
    [ChartTypes.lineChartStepAfter]: this.getLineY,
    [ChartTypes.stackedAreaChart]: this.setStackedY,
    [ChartTypes.comboChart]: this.getLineY,
    [ChartTypes.clusteredBarChart]: this.getLineY,
    [ChartTypes.area]: this.getLineY,
    [ChartTypes.line]: this.getLineY,
  };

  public getChartData(params: ChartDataCreationParams): {
    data: ChartData[];
    xMinApplied: boolean;
    xMaxApplied: boolean;
    yMinApplied: boolean;
    yMaxApplied: boolean;
  } {
    const {
      plans,
      selectedMetrics,
      keyX,
      xMin = null,
      xMax = null,
      yMin = null,
      yMax = null,
      metricIdKey = 'key',
      chartType,
      area,
      metricBasedArea,
      metricBase = false,
      chartInterpolate,
      xAxisMetric,
      isStaticMetric,
      metricDataSource,
    } = params;
    let yMinApplied = false;
    let yMaxApplied = false;
    const func = metricBase
      ? isStaticMetric
        ? this.getDataStaticMetricsBase.bind(this)
        : this.getDataMetricBase.bind(this)
      : this.getDataPlanBase.bind(this);
    const { result, xMinApplied, xMaxApplied } = func({
      plans,
      selectedMetrics,
      keyX,
      xMin,
      xMax,
      metricIdKey,
      area,
      metricBasedArea,
      chartType,
      chartInterpolate,
      xAxisMetric,
      metricDataSource,
    });

    if (result.length && (some(selectedMetrics, ['isIRR', true]) || some(selectedMetrics, ['isPercentYAxis', true]))) {
      const resY = this.applyYLimitation(yMin, yMax, result, chartType);
      resY.yMinApplied && (yMinApplied = resY.yMinApplied);
      resY.yMaxApplied && (yMaxApplied = resY.yMaxApplied);
    }

    return { data: result, xMinApplied, xMaxApplied, yMinApplied, yMaxApplied };
  }

  private getDataMetricBase(params: ChartDataCreationParams): MappedChartData {
    const result: ChartData[] = [];
    let xMinApplied = false;
    let xMaxApplied = false;

    if (params.selectedMetrics) {
      params.selectedMetrics.forEach((metric: Metric, i: number) => {
        const plan = this.getPlan(params.plans, metric.productIndex);

        if (plan) {
          const data =
            metric.chartType !== ChartTypes.clusteredBarChart
              ? get(plan, CALCULATED_DATA_TARGET.data)
              : get(plan, CALCULATED_DATA_TARGET.metadata);

          const isTotalLTCBalance = metric.key === 'total_ltc_balance';
          const chartData: ChartData = {
            chartType: metric.chartType as LinesChartType,
            stepLineType: metric.stepLineType as MetricStepType,
            disable: metric.disable,
            key: String(i),
            order: metric.order,
            planId: plan.id,
            metricId: metric[params.metricIdKey],
            values: [],
            xType: 'number',
            yType: 'currency',
            isPercentYAxis: metric.isPercentYAxis,
            isWholeNumberYAxis: metric.isWholeNumberYAxis,
            area: params.metricBasedArea ? metric.background === 'solid' : params.area,
            metricTitle: metric.metricTitle,
            interpolate: params.chartInterpolate as MetricInterpolate,
            isTotalLTCBalance: isTotalLTCBalance,
            isTotalLTCBalanceUnlimited: isTotalLTCBalance && data[metric.key]?.every(item => item === 'Unlimited'),
            isTotalLTCBalanceNumbers: isTotalLTCBalance && data[metric.key]?.every(item => item !== 'Unlimited'),
            stackedMetric: metric.stackedMetric,
            stackedGroup: metric.stackedGroup,
            opacity: metric.opacity,
            isCustomMetric: metric.isCustomMetric,
            yAxis: metric.yAxis,
            productIndex: metric?.productIndex,
          };

          if (params.chartType === ChartTypes.lineChart && !params.chartInterpolate) {
            chartData.interpolate = metric.interpolate;
          }

          if (data[metric.key]) {
            const planDataKeyX = params.xAxisMetric ? params.xAxisMetric : params.keyX;
            this.setChartDataItems(data, planDataKeyX, metric, chartData);
            chartData.planDataKeyX = planDataKeyX;
          }

          if (chartData.values.length) {
            const resX = this.applyXLimitation(params.xMin, params.xMax, chartData);
            resX.xMinApplied && (xMinApplied = resX.xMinApplied);
            resX.xMaxApplied && (xMaxApplied = resX.xMaxApplied);
          } else {
            chartData.isEmptyPlan = true;
          }

          const validTotalLTCBalance =
            chartData.isTotalLTCBalance && (chartData.isTotalLTCBalanceUnlimited || chartData.isTotalLTCBalanceNumbers);

          if (validTotalLTCBalance || !chartData.isTotalLTCBalance) {
            result.push(chartData);
          }
        }
      });
    }

    return { result, xMinApplied, xMaxApplied };
  }

  private getDataStaticMetricsBase(params: ChartDataCreationParams): MappedChartData {
    const result: ChartData[] = [];
    const staticMetric = params.selectedMetrics[0];
    const { metricKeyData, metricKeyLabel } = staticMetric.staticMetrics;
    const plan = this.getPlan(params.plans, staticMetric.productIndex);

    if (!plan || !metricKeyData || !metricKeyLabel) {
      return { result };
    }

    const data = get(plan, params.metricDataSource);
    const planDataKeyX = params?.xAxisMetric ? params.xAxisMetric : params.keyX;
    const values = this.getValuesForStaticMetrics(data, planDataKeyX, metricKeyData, params.metricDataSource);
    const labels = data[metricKeyLabel.key];

    values.forEach((value: PointData, i: number) => {
      if (plan && labels && labels.length) {
        const chartData: ChartData = {
          chartType: params.chartType as ChartType,
          key: String(i),
          order: staticMetric.order,
          planId: plan.id,
          metricId: i,
          metricTitle: labels[i],
          values: [value],
          xType: 'number',
          yType: 'currency',
        };

        if (chartData.values.length === 0) {
          chartData.isEmptyPlan = true;
        }

        result.push(chartData);
      }
    });

    return { result };
  }

  private getDataPlanBase({ plans, selectedMetrics, keyX, xMin, xMax, area, chartType }): MappedChartData {
    const result: ChartData[] = [];
    let xMinApplied = false;
    let xMaxApplied = false;
    plans.forEach((plan: CareerPlan, i: number) => {
      const metric = selectedMetrics[0];

      if (plan) {
        const data = get(plan, CALCULATED_DATA_TARGET.data);
        const isTotalLTCBalance = metric.key === 'total_ltc_balance';
        const chartData: ChartData = {
          disable: plan.disable,
          key: String(i),
          planId: plan.id,
          metricId: Number(plan.id),
          values: [],
          xType: 'number',
          yType: 'currency',
          isPercentYAxis: metric.isPercentYAxis,
          chartType,
          area,
          metricTitle: metric.metricTitle,
          isTotalLTCBalance: isTotalLTCBalance,
          isTotalLTCBalanceUnlimited: isTotalLTCBalance && data[metric.key]?.every(item => item === 'Unlimited'),
          isTotalLTCBalanceNumbers: isTotalLTCBalance && data[metric.key]?.every(item => item !== 'Unlimited'),
        };

        if (data[metric.key]) {
          this.setChartDataItems(data, keyX, metric, chartData);
          chartData.planDataKeyX = keyX;
        }

        if (chartData.values.length) {
          const resX = this.applyXLimitation(xMin, xMax, chartData);
          resX.xMinApplied && (xMinApplied = resX.xMinApplied);
          resX.xMaxApplied && (xMaxApplied = resX.xMaxApplied);
        } else {
          chartData.isEmptyPlan = true;
        }

        const validTotalLTCBalance =
          chartData.isTotalLTCBalance && (chartData.isTotalLTCBalanceUnlimited || chartData.isTotalLTCBalanceNumbers);

        if (validTotalLTCBalance || !chartData.isTotalLTCBalance) {
          result.push(chartData);
        }
      }
    });

    return { result, xMinApplied, xMaxApplied };
  }

  public agePolicyYearConvert(
    propertyValue: string | number,
    graphValue: XAxisSourceType,
    carrierPlans: CareerPlan[]
  ): any {
    const age = 'eoy_age';
    const year = 'policy_year';
    const ages = this.getLongestPlanDataByKey(carrierPlans, age);
    const years = this.getLongestPlanDataByKey(carrierPlans, year);
    const index = indexOf(graphValue === age ? years : ages, propertyValue?.toString());

    if (index === -1) return null;

    return graphValue === 'eoy_age' ? ages[index] : years[index];
  }

  private getLongestPlanDataByKey(plans: CareerPlan[], key: string): string[] {
    return maxBy(
      plans.map((plan: CareerPlan) => plan.configjson.data[key]),
      (age: any) => age.length
    );
  }

  private getPlan(plans: CareerPlan[], productIndex: number | string): CareerPlan {
    if (!isNil(productIndex)) {
      return plans[productIndex];
    }

    return plans[0];
  }

  private applyXLimitation(xMin: number, xMax: number, chartData: ChartData) {
    if (chartData.chartType === ChartTypes.clusteredBarChart) {
      return { xMinApplied: false, xMaxApplied: false };
    }

    const xMinApplied = !isNil(xMin) && first(chartData.values).x < xMin;
    const xMaxApplied = !isNil(xMax) && last(chartData.values).x > xMax;
    chartData.values = filter(chartData.values, (item: PointData) => {
      return (isNil(xMin) || item.x >= xMin) && (isNil(xMax) || item.x <= xMax);
    });

    return { xMinApplied, xMaxApplied };
  }

  private applyYLimitation(
    yMin: number,
    yMax: number,
    data: ChartData[],
    chartType: ChartType | LinesChartType = ChartTypes.lineChart
  ) {
    const cloneData = cloneDeep(data);
    const yValues = chartType === ChartTypes.lineChart ? this.getValues(data) : this.getValuesSum(data);
    const yMinApplied = !isNil(yMin) && min(yValues) < yMin;
    const yMaxApplied = !isNil(yMax) && max(yValues) > yMax;
    const isApplyY = [ChartTypes.multiBarChart, ChartTypes.pie, ChartTypes.donut].includes(chartType);

    data.forEach((chartData: ChartData, index: number) => {
      if (chartData.isPercentYAxis && !isApplyY) {
        chartData.values.forEach((value: { x: number; y: number }, i: number) => {
          this.dataY[chartType](value, yMin, yMax, i, index, cloneData);
        });
      }
    });

    return { yMinApplied, yMaxApplied };
  }

  private getValuesSum(data: ChartData[]) {
    const yValues: number[][] = data
      .map<PointData[]>(item => item.values)
      .map(value => value.map((item: PointData) => item.y));
    const longestValues: number[] = maxBy(yValues, (yValue: number[]) => yValue.length);
    longestValues.forEach((value: number, i: number) => {
      value = 0;
      yValues.forEach((item: number[]) => !isNil(item[i]) && (value += item[i]));
      longestValues[i] = value;
    });

    return longestValues;
  }

  private getValues(data: ChartData[]): number[] {
    const allValues = [];
    data.forEach(metric => allValues.push(...metric.values.map(value => value.y)));

    return allValues;
  }

  private setStackedY(
    value: { x: number; y: number },
    yMin: number,
    yMax: number,
    index: number,
    indexParent: number,
    data: ChartData[]
  ) {
    const values: PointData[][] = data.map(item => item.values);
    let sum = 0;
    values.forEach((valuesArray: PointData[]) => (sum += get(valuesArray, `[${index}].y`) || 0));

    if (sum > yMax) {
      sum = 0;
      values.forEach((valuesArray: PointData[], i: number) => {
        const valueY = get(valuesArray, `[${index}].y`) || 0;

        if (i < indexParent) {
          sum += valueY;
        }

        if (!isNil(yMax) && i === indexParent && sum + valueY > yMax) {
          value.y = yMax > sum ? yMax - sum : 0;
        }

        if (!isNil(yMin) && i === indexParent && sum + valueY < yMin) {
          value.y = yMin;
        }
      });
    }
  }

  private getLineY(value: { x: number; y: number }, yMin: number, yMax: number) {
    if (!isNil(yMin) && value.y < yMin) {
      value.y = yMin;
    }

    if (!isNil(yMax) && value.y > yMax) {
      value.y = yMax;
    }
  }

  private setChartDataItems(data: PlanData, keyX: string, metric: Metric, chartData: ChartData) {
    const isBarChart = metric.chartType === ChartTypes.clusteredBarChart;
    const isComboBarChart = metric.chartType === ChartTypes.bar;
    const keyXData = isBarChart ? [data[metric.key]] : data[keyX];

    keyXData?.forEach((age: string, i: number) => {
      const x = isBarChart ? Math.round(parseFloat(age)) : parseInt(age, 10);
      const y = this.getYValue(data, metric, i, chartData);

      if (!isNil(y) && !isNaN(y) && !isComboBarChart) {
        chartData.values.push(isBarChart ? { x: y, y: x } : { x, y });
      } else if (isComboBarChart) {
        chartData.values.push({ x, y });
      }
    });
  }

  private getYValue(data, metric, i, chartData): number | null {
    let rawValue = data[metric.key][i];

    if (rawValue === null && metric.chartType === 'bar') {
      return null;
    }

    if (chartData.isTotalLTCBalance && chartData.isTotalLTCBalanceUnlimited) {
      return 0;
    }

    if (rawValue === null && chartData.values.length) {
      rawValue = chartData.values[chartData.values.length - 1].y;
    }

    return Math.round(parseFloat(rawValue) * 100) / 100;
  }

  private getValuesForStaticMetrics(
    data: PlanData,
    keyX: string,
    metric: Metric,
    metricSource: DataTarget
  ): PointData[] {
    const values = [];

    const byKeyX = () => {
      if (!data[keyX]) {
        return;
      }

      data[keyX].forEach((age: string, i: number) => {
        const x = parseInt(age, 10);
        const y = ceil(
          parseFloat(data[metric.key][i] === null && values.length ? values[values.length - 1].y : data[metric.key][i]),
          2
        );
        isNil(y) || isNaN(y) || values.push({ x, y });
      });
    };

    const byMetricKey = () => {
      if (!data[metric.key] || !isArray(data[metric.key])) {
        return;
      }

      data[metric.key].forEach((value: string) => {
        const y = ceil(parseFloat(value === null && values.length ? values[values.length - 1].y : value), 2);
        isNil(y) || isNaN(y) || values.push({ x: y, y });
      });
    };

    metricSource === CALCULATED_DATA_TARGET.metadata ? byMetricKey() : byKeyX();

    return values;
  }
}

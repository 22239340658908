import { Profile } from '@assurance/um-services';

export const AUTH_KEYS = [
  'user',
  'organization',
  'userPermissions',
  'groupRules',
  'groupPermissions',
  'portals',
] as (keyof Profile)[];

import { Organization, OrganizationSwitch, Profile } from '@assurance/um-services';
import { Action, createReducer, on } from '@ngrx/store';

import * as Actions from './global.actions';
import { CoverLetters } from '@shared/models';

export interface GlobalState {
  profile: Partial<Profile>;
  dependentPermissions: Record<string, string[] | null>;
  coverLetter: CoverLetters;
  pdfGeneration: boolean;
  loading: boolean;
  coverLetterOrientation: {
    landscape: boolean;
  };
  organizationsSwitch: {
    data: OrganizationSwitch<Organization>;
    pending: boolean;
    success: boolean;
    error: { message: string; stack?: string } | null; // Updated to store only serializable fields
    getByProfile: boolean;
  };
}

export const initialState: GlobalState = {
  profile: {},
  dependentPermissions: null,
  coverLetter: {
    data: null,
    loading: false,
    loaded: false,
    error: false,
  },
  coverLetterOrientation: {
    landscape: true,
  },
  organizationsSwitch: {
    data: null,
    pending: null,
    success: null,
    error: null,
    getByProfile: false,
  },
  loading: false,
  pdfGeneration: false,
};

const globalReducer = createReducer(
  initialState,
  on(Actions.globalCoverLetterLoadFailure, (state, { error }) => ({
    ...state,
    coverLetter: {
      ...state.coverLetter,
      loading: false,
      loaded: false,
      error,
    },
  })),
  on(Actions.globalCoverLetterLoadPending, state => ({
    ...state,
    coverLetter: {
      ...state.coverLetter,
      loading: true,
      loaded: false,
    },
  })),
  on(Actions.globalCoverLetterLoadSuccess, (state, { payload }) => ({
    ...state,
    coverLetter: {
      ...state.coverLetter,
      data: payload,
      loading: false,
      loaded: true,
      error: false,
    },
  })),
  on(Actions.globalPdfGeneration, (state, { payload }) => ({
    ...state,
    pdfGeneration: payload,
  })),
  on(Actions.globalCoverLetterUpdateSuccess, (state, { payload }) => ({
    ...state,
    coverLetter: {
      ...state.coverLetter,
      data: payload,
      loading: false,
      loaded: true,
      error: false,
    },
  })),
  on(Actions.globalCoverLetterUpdateFailure, (state, { error }) => ({
    ...state,
    coverLetter: {
      ...state.coverLetter,
      loading: false,
      loaded: false,
      error: error,
    },
  })),
  on(Actions.globalCoverLetterUpdatePending, state => ({
    ...state,
    coverLetter: {
      ...state.coverLetter,
      loading: true,
      loaded: false,
    },
  })),
  on(Actions.globalStateReset, () => ({
    ...initialState,
  })),
  on(Actions.coverSheetLandscape, (state, { payload }) => ({
    ...state,
    coverLetterOrientation: {
      ...state.coverLetterOrientation,
      landscape: payload,
    },
  })),
  on(Actions.setLoading, (state, { loading }) => ({
    ...state,
    loading: loading,
  })),
  on(Actions.setDependentPermissions, (state, { dependentPermissions }) => ({
    ...state,
    dependentPermissions,
  })),
  on(Actions.setProfileGroupPermissions, (state, { groupPermissions }) => ({
    ...state,
    profile: {
      ...state.profile,
      groupPermissions,
    },
  })),
  on(Actions.setProfileGroupRules, (state, { groupRules }) => ({
    ...state,
    profile: {
      ...state.profile,
      groupRules,
    },
  })),
  on(Actions.loadOrganizationsSwitchPending, state => ({
    ...state,
    organizationsSwitch: {
      ...state.organizationsSwitch,
      pending: true,
    },
  })),
  on(Actions.loadOrganizationsSwitchByProfile, state => ({
    ...state,
    organizationsSwitch: {
      ...state.organizationsSwitch,
      getByProfile: true,
    },
  })),
  on(Actions.loadOrganizationsSwitchSuccess, (state, { payload }) => ({
    ...state,
    organizationsSwitch: {
      ...state.organizationsSwitch,
      data: payload,
      pending: false,
      success: true,
    },
  })),
  on(Actions.loadOrganizationsSwitchError, (state, { payload }) => ({
    ...state,
    organizationsSwitch: {
      ...state.organizationsSwitch,
      pending: false,
      success: false,
      error: { message: payload?.message, stack: payload?.stack },
    },
  }))
);

export function reducer(state: GlobalState | undefined, action: Action): GlobalState {
  return globalReducer(state, action);
}

export enum PermissionKeyEnum {
  intelligent_quote_link_button = 'intelligent_quote_link_button',
  access_to_eum = 'access_to_eum',

  view_custom_pages = 'VIEW_CUSTOM_PAGE',
  create_custom_page = 'CREATE_CUSTOM_PAGE',
  edit_custom_page = 'EDIT_CUSTOM_PAGE',
  manage_custom_page_versions = 'MANAGE_CUSTOM_PAGE_VERSIONS',
  delete_custom_page = 'DELETE_CUSTOM_PAGE',
  export_custom_page = 'EXPORT_CUSTOM_PAGE',
  import_custom_page = 'IMPORT_CUSTOM_PAGE',
  edit_text_on_custom_page = 'EDIT_TEXT_ON_CUSTOM_PAGE',
  view_custom_page_change_log = 'VIEW_CUSTOM_PAGE_CHANGE_LOG',
  revert_custom_page_changes = 'REVERT_CUSTOM_PAGE_CHANGES',
  manage_system_templates = 'MANAGE_SYSTEM_TEMPLATES',

  view_policy_inforce_records = 'VIEW_POLICY_INFORCE_RECORDS',
  view_policy_inforce_annual_statements = 'VIEW_POLICY_INFORCE_ANNUAL_STATEMENTS',
  export_sales_stories_data_to_csv = 'EXPORT_SALES_STORIES_DATA_TO_CSV',

  lock_presentation = 'LOCK_PRESENTATION',
  prsnt_edit = 'PRSNT_EDIT',
  drop_ticket = 'drop_ticket',
  create_own_template_ep = 'CREATE_OWN_TEMPLATE_EP',
  view_own_and_distributed_templates_ep = 'VIEW_OWN_AND_DISTRIBUTED_TEMPLATES_EP',
  view_organization_template_ep = 'VIEW_ORGANIZATION_TEMPLATE_EP',
  view_child_organization_template_ep = 'VIEW_CHILD_ORGANIZATION_TEMPLATE_EP',
  RESTRICT_DEFAULT_PAGES_LTC = 'restrict_default_presentation_pages_for_long_term_care',

  view_shared_case = 'VIEW_SHARED_CASE',
  edit_shared_case = 'EDIT_SHARED_CASE',
  use_presentation_name_as_pdf_file_name = 'use_presentation_name_as_pdf_file_name',
  hide_created_date_on_pdf = 'hide_created_date_on_pdf',

  define_custom_page_versions = 'CHANGE_SALES_STORY_ADDED_DATE',
  exclude_from_analytic_tools_ep = 'EXCLUDE_FROM_ANALYTIC_TOOLS_EP',
  open_presentation_setup = 'OPEN_PRESENTATION_SETUP_FROM_THE_SHAREABLE_LINK',

  share_presentation_by_email = 'SHARE_PRESENTATION_BY_EMAIL',
  view_users_list = 'USERS_LIST',
  add_shareable_link_in_the_pdf_file_footer = 'add_shareable_link_in_the_pdf_file_footer',
  single_view_hidden_on_shareable_link_and_pdf = 'single_view_hidden_on_shareable_link_and_pdf',
  product_table_hidden_on_shareable_link_and_pdf = 'product_table_hidden_on_shareable_link_and_pdf',
  spreadsheet_hidden_on_shareable_link_and_pdf = 'spreadsheet_hidden_on_shareable_link_and_pdf',
  charts_hidden_on_shareable_link_and_pdf = 'charts_hidden_on_shareable_link_and_pdf',

  data_consumption_configurations = 'DATA_CONSUMPTION_CONFIGURATION',
}

import { Params } from '@angular/router';
import { Organization, OrganizationSwitch, Profile } from '@assurance/um-services';
import { props, createAction } from '@ngrx/store';

export const globalCoverLetterUpdateSuccess = createAction(
  '[Global] Cover Letter Update Cover Success',
  props<{ payload: any }>()
);
export const globalCoverLetterUpdatePending = createAction('[Global] Cover Letter Update Cover Pending');
export const globalCoverLetterUpdateFailure = createAction(
  '[Global] Cover Letter Update Cover Failure',
  props<{ error: any }>()
);
export const globalCoverLetterLoadSuccess = createAction(
  '[Global] Cover Letter Load Success',
  props<{ payload: any }>()
);
export const globalCoverLetterLoadPending = createAction('[Global] Cover Letter Load Pending');
export const globalCoverLetterLoadFailure = createAction('[Global] Cover Letter Load Failure', props<{ error: any }>());
export const globalStateReset = createAction('[Global] State Reset');
export const coverSheetLandscape = createAction(
  '[Global] Cover Sheet landscape orientation',
  props<{ payload: boolean }>()
);
export const globalPdfGeneration = createAction('[Global] Pdf is being generated', props<{ payload: boolean }>());
export const setLoading = createAction('[Global] Set Loading', props<{ loading: boolean }>());

export const setDependentPermissions = createAction(
  '[Global] Set Dependent Permissions',
  props<{ dependentPermissions: Record<string, string[]> }>()
);

export const setProfileGroupPermissions = createAction(
  '[Global] Set Profile Group Permissions',
  props<{ groupPermissions: Profile['groupPermissions'] }>()
);

export const setProfileGroupRules = createAction(
  '[Global] Set Profile Group Rules',
  props<{ groupRules: Profile['groupRules'] }>()
);

export const organizationsSwitch = createAction(
  '[Global] Organizations Switch',
  props<{ userId?: number; queryParams?: Params }>()
);

export const loadOrganizationsSwitchPending = createAction('[Global] Load Organizations Switch Pending');

export const loadOrganizationsSwitchByProfile = createAction('[Global] Load Organizations Switch By Profile');

export const loadOrganizationsSwitchSuccess = createAction(
  '[Global] Load Organizations Switch Success',
  props<{ payload: OrganizationSwitch<Organization> }>()
);

export const loadOrganizationsSwitchError = createAction(
  '[Global] Load Organizations Switch Error',
  props<{ payload: Error }>()
);

export const organizationsSwitchNavigate = createAction(
  '[Global] Organizations Switch Navigate',
  props<{ payload: OrganizationSwitch<Organization>; queryParams?: Params }>()
);
